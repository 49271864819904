import { Style } from "./style";
import PrimaryLayout from "../../Components/Layout/PrimaryLayout";
import HelmetFunction from "../../Components/Helmet";
import { pallete } from "../../theme";

export default function ContactUs() {
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='درباره ما' />
      <Style>
        <div className="container-l">
          <div className="contact-top flex flex-column gap-10 mtb-10">
            <div className="flex align-center gap-5">
              <img className="contact-top-icon" src="/asset/image/contactus.png" />
              <h1 className="contact-top-h">تماس با ما</h1>
            </div>
            <div className="contact-top-b flex align-center gap-5">
              <i className="fa-solid fa-phone" style={{ color: `${pallete.primary}` }}></i>
              <span>شماره تماس: </span>
              <span>09380386948</span>
            </div>
            <div className="contact-top-b flex align-center gap-5">
              <i className="fa-regular fa-envelope" style={{ color: `${pallete.primary}` }}></i>
              <span>آدرس ایمیل: </span>
              <span>info@tadarox.ir</span>
            </div>
          </div>
        </div>
      </Style>
    </PrimaryLayout>
  );
}
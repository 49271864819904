import { Style } from "./style";
import { baseDirection } from "../../../../Utils/FileHandling";
import { ProductClass } from "../../../../Components/Categories/ProductCategories";
import { api } from '../../../../Utils/api';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from '../../../../Components/Loading';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from "react-router-dom";


export default function CompanyProducts() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    api
      .get(`products/user_id/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  function renderFarm() {
    return (
      data.map((item, index) => {
        return (
          <SwiperSlide className="item" key={index}>
            <Link to={`/products/product/${item.id}`}>
              <div className="flex flex-column gap-20">
                <div className="flex flex-column gap-5">
                  <h1 className="item-title">{item.title.length > 12 ? <>{item.title.substring(0, 12)} ...</> : item.title}</h1>
                  <div className="item-model flex align-center gap-5">
                    <p className="item-model-t">مدل: </p>
                    <p className="item-model-c">{item.model.length > 12 ? <>{item.model.substring(0, 12)} ...</> : item.model}</p>
                  </div>
                  <div className="item-counterpart flex align-center gap-5">
                    <p className="item-counterpart-t">نمونه مشابه: </p>
                    <p className="item-counterpart-c">{item.counterpart && (item.counterpart.length > 12 ? <>{item.counterpart.substring(0, 12)} ...</> : item.counterpart)}</p>
                  </div>
                  <div className="item-category flex align-center gap-5">
                    <p className="item-category-t">دسته بندی: </p>
                    <p className="item-category-c">{<ProductClass category={item.category} />}</p>
                  </div>
                </div>
                <div className="flex justify-center align-center">
                  <img className="item-image" src={baseDirection.images.concat(item.image)} />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })
    );
  }
  if (data.length === 0) {
    return null;
  }
  return (
    <Style>
      <div className="flex align-center justify-between nowrap">
        <h3 className="title">{'محصولات'}</h3>
        <Link to={'/products'} className="see-all flex align-center gap-5">
          <span>مشاهده همه</span>
          <i className="fa-solid fa-angles-left"></i>
        </Link>
      </div>
      <Swiper
        navigation
        slidesPerView={1}
        spaceBetween={5}
        modules={[Navigation]}
        breakpointsBase="window"
        breakpoints={{
          250: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          380: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          520: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          750: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 5,
          },
          1260: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        className="mySwiper"
      >
        {loading ? <Loading /> : renderFarm()}
      </Swiper>
    </Style>
  );
}
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Auth from "./Pages/Auth";
import SubmitForm from "./Pages/UserProfile/Submition";
import Profile from "./Pages/UserProfile";
import Forbidden from './Pages/403';
import NotFound from "./Pages/404";
import ServerError from "./Pages/500";
import Products from "./Pages/Product/Products";
import SearchPage from "./Pages/Search";
import CategorisedProducts from "./Pages/Product/Products/Categories";
import SingleProduct from "./Pages/Product/SingleProduct";
import Rfqs from "./Pages/Rfq/Rfqs";
import SingleRfq from "./Pages/Rfq/SingleRfq";
import Companies from "./Pages/Company/Companies";
import Company from "./Pages/Company/SingleCompany";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";

const routes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/auth',
    element: <Auth />
  },
  {
    path: '/profile/:id',
    element: <Profile />
  },
  {
    path: '/submit/:id',
    element: <SubmitForm />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/companies',
    element: <Companies />
  },
  {
    path: '/companies/company/:id',
    element: <Company />
  },
  {
    path: '/products/categories/:category',
    element: <CategorisedProducts />
  },
  {
    path: '/products/product/:id',
    element: <SingleProduct />
  },
  {
    path: '/rfqs',
    element: <Rfqs />
  },
  {
    path: '/rfqs/rfq/:id',
    element: <SingleRfq />
  },
  {
    path: '/search',
    element: <SearchPage />
  },
  {
    path: '/aboutus',
    element: <AboutUs />
  },
  {
    path: '/contactus',
    element: <ContactUs />
  },
  {
    path: '/forbidden',
    element: <Forbidden />
  },
  {
    path: '/server_error',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  },
];
const router = createBrowserRouter(routes);
export default function Router() {
  return (
    <RouterProvider router={router} />
  );
}
import { Link } from "react-router-dom";
import Email from "../../../Components/uiElements/Antd/Email";
import FormBuilder from "../../../Components/uiElements/Antd/Form";
import { Style } from "./style";
import Submit from "../../../Components/uiElements/Antd/Button";
import Password from "../../../Components/uiElements/Antd/Password";
import ConfirmPassword from "../../../Components/uiElements/Antd/ConfirmPassword";
import Selection from "../../../Components/uiElements/Antd/Select";
import CheckBox from "../../../Components/uiElements/Antd/CheckBox";
import HelmetFunction from "../../../Components/Helmet";
import InputElement from "../../../Components/uiElements/Antd/Input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from '../../../Utils/api';
import { message } from "antd";


const profile_types = [
  {
    value: 'seller',
    label: 'شرکت فروشنده',
  },
  {
    value: 'buyer',
    label: 'شرکت خریدار',
  },
  {
    value: 'individual',
    label: 'شخص حقیقی',
  },
];
const rules = [
  {
    validator: (_, value) =>
      value ? Promise.resolve() : Promise.reject(new Error('قوانین را مطالعه و تائید نمایید.')),
  },
];

export default function Register() {
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const [msg, setMsg] = useState();
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    api
      .post('/register', values, {
        Headers: {
          'Content-Type': 'application/json;Charset:UTF-8'
        }
      })
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        setMsg(error.response.data.message);
      });
  };
  if (token) {
    navigate('/');
  }

  return (
    <Style>
      <HelmetFunction title='ثبت نام در تدارکس' />
      <FormBuilder name='register' onFinish={onFinish}>
        <Selection name='profile_type' label='نوع حساب کاربری' rules={[
          {
            required: true,
            message: 'نوع پروفایل خود را انتخاب کنید!',
          },
        ]} options={profile_types} placeholder={'نوع حساب کاربری'} />
        <InputElement name='username' label='نام کاربری' rules={
          [
            {
              required: true,
              message: 'نام کاربری خود را وارد کنید'
            },
          ]} showCount={true} maxLength={30} />
        <Email />
        <Password hasFeedback={true} />
        <ConfirmPassword dependencies={['password']} />
        <CheckBox rules={rules}>
          <div><Link to="/">قوانین</Link><span> را مطالعه کردم و مورد قبول می باشد.</span></div>
        </CheckBox>
        <Submit type={'primary'} htmlType={'submit'}>ثبت نام در تدارکس</Submit>
      </FormBuilder>
    </Style>
  );
}
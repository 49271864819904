import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { Style } from './style';

export default function Forbidden() {
    return (
        <Style>
            <Result
                status="403"
                title="403"
                subTitle="شما دسترسی لازم به این صفحه را ندارید"
                extra={<Link to={'/'}><Button size='large' type="primary">برگشت به صفحه اول</Button></Link>}
            />
        </Style>
    );
}
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import GlobalStyle from './GlobalStyle';
import { ConfigProvider } from 'antd';
import { pallete } from './theme';
import ScrollToTop from './Components/ScrollToTop';
import RemoveUerInfo from './Components/LocalStorage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Fragment>
    <GlobalStyle />
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: pallete.primary,
          fontFamily: pallete.fontFamily
        }
      }}>
      <Router>
        <ScrollToTop />
        <RemoveUerInfo />
      </Router>
    </ConfigProvider>
  </Fragment>
);


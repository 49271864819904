import styled from "styled-components";
import { device, pallete } from "../../../theme";

export const Style = styled.div`
width: 100%;
height: 465px;
padding: 15px;
margin-top: 15px;
  .join-title {
    color: ${pallete.defaultBtnColor};
    font-size: 2rem;
    padding: 10px;
  }
  .see-all {
    font-size: 1.2rem;
    color: ${pallete.primary};
  }
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 50%);
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: ${pallete.primary};
      transition: all 0.3s ease;    
      &:after {
        font-size: 1.6rem;
      }   
    }
    .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 20px);
      right: auto;
      top: var(--swiper-navigation-top-offset, 50%);  
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: ${pallete.primary};
      transition: all 0.3s ease;  
      &:after {
        font-size: 1.6rem;
      }   
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: ${pallete.whiteColor1};
    min-height: 385px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`;
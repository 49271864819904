import { Style } from "./style";
import PrimaryLayout from '../../../Components/Layout/PrimaryLayout';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from '../../../Components/Loading';
import { ProductClass } from "../../../Components/Categories/ProductCategories";
import HandleNumberFormat from "../../../Components/NumberFormat";
import HandleCurrency from "../../../Components/Currency";
import { api } from "../../../Utils/api";
import Button from "../../../Components/uiElements/Button";
import { Link } from "react-router-dom";
import { baseDirection } from "../../../Utils/FileHandling";
import HelmetFunction from "../../../Components/Helmet";
import { pallete } from "../../../theme";

export default function SingleProduct() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [btnSize, setBtnSize] = useState('large');
  const [data, setData] = useState({
    id: '',
    title: '',
    model: '',
    category: '',
    description: '',
    counterpart: '',
    counterpart_description: '',
    price: '',
    currency: '',
    image: '',
    file: '',
    producer_name: '',
  });
  useEffect(() => {
    setLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    api
      .get(`product/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.product);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error.response.message);
      });
  }, []);
  useEffect(() => {
    handleBtnSize();
  }, []);
  function handleBtnSize() {
    const width = window.innerWidth;
    if (width > 500) {
      setBtnSize('large');
    } else {
      setBtnSize('medium');
    }
  }
  function handleNumberFormat(value) {
    return ((value).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }
  function handleCurrency(currency) {
    if (currency === 'IRR') {
      return 'ریال';
    } else if (currency === 'USD') {
      return 'دلار';
    } else if (currency === 'EUR') {
      return 'یورو';
    } else if (currency === 'AED') {
      return 'درهم';
    } else if (currency === 'CNY') {
      return 'یوان';
    }
  }
  function renderFarm() {
    return (
      <div className="container-l">
        <div className="inner">
          <div className="content flex justify-center flex-column gap-10">
            <div className="box flex justify-between nowrap">
              <div className="intro flex flex-column gap-15">
                <div className="title flex align-center gap-5">
                  <h1 className="title-c">{data.title}</h1>
                </div>
                <div className="flex flex-column gap-10">
                  <div className=" model flex align-center gap-5">
                    <p className="model-t">مدل: </p>
                    <p className="model-c">{data.model}</p>
                  </div>
                  <div className="category flex align-center gap-5">
                    <p className="category-t">دسته بندی: </p>
                    <p className="category-c">{<ProductClass category={data.category} />}</p>
                  </div>
                  <div className="producer flex align-center gap-5">
                    <p className="producer-t">شرکت تولید کننده: </p>
                    <p className="producer-c">{data.producer_name}</p>
                  </div>
                  {data.counterpart && <div className="counterpart flex align-center gap-5">
                    <p className="counterpart-t">نمونه مشابه: </p>
                    <p className="counterpart-c">{data.counterpart}</p>
                  </div>}
                  {data.price && <div className="price flex align-center gap-5">
                    <p className="price-t">قیمت: </p>
                    <p className="price-c">{data.price ? handleNumberFormat(data.price) : '-'}</p>
                    <p className="price-u">{handleCurrency(data.currency)}</p>
                  </div>}
                </div>
              </div>
              {data.image && <div className=" image flex justify-center align-center">
                <img className="img" src={baseDirection.images.concat(data.image)} />
              </div>}
            </div>
            <div className="desc flex flex-column gap-10">
              {data.description && <div className="desc-m flex flex-column gap-5">
                <p className="desc-m-t">شرح محصول: </p>
                <p className="desc-m-c">{data.description}</p>
              </div>}
              {data.counterpart_description && <div className="desc-c flex flex-column gap-5">
                <p className="desc-c-t">شرح نمونه مشابه: </p>
                <p className="desc-c-c">{data.counterpart_description}</p>
              </div>}
            </div>
            <div className="download flex align-center justify-center">
              <Button target='_blanck' href={data.file && baseDirection.files.concat(data.file)} type="primary" size={btnSize}>دانلود مشخصات فنی</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title={`محصول ${data.title} در تدارکس`} />
      <Style>
        {loading ? <Loading /> : renderFarm()}
      </Style>
    </PrimaryLayout>
  );
}
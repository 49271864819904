import styled from "styled-components";
import { pallete, device } from "../../../theme";

export const Style = styled.div`
color: ${pallete.defaultBtnColor};
.title {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 5px 0;
  margin-bottom: 5px;
  color: ${pallete.whiteColor1};
}
.item {
  font-size: 1.4rem;
  color: ${pallete.defaultBtnColor};
  transition: all 0.3s ease;
  &:hover {
    color: ${pallete.primary};
  }
}
@media only screen and ${device.sm} {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .col-8 {
    width: 100%;
  }
}
`;
import JoiningSlider from "../Slider/JoiningSlide";
import { Style } from "./style";
import Button from '../uiElements/Button';
import { useEffect, useState } from "react";

const items = [
  {
    icon: <img src="asset/image/benefit-1.png" />,
    title: 'شناخته شدن در صنعت کشور',
    body: 'با تکمیل پروفایل خود محصولات، خدمات و تخصص خود را با فعالان عرصه صنعت کشور به اشتراک بگذارید.',
  },
  {
    icon: <img src="asset/image/benefit-2.png" />,
    title: 'دریافت درخواست پیشنهاد قیمت',
    body: 'با معرفی محصولات خود در تدارکس، پیشنهاد ارائه قیمت از طرف مشتریان را دریافت نمایید.',
  },
  {
    icon: <img src="asset/image/experience.png" />,
    title: 'ارتباط با مشتریان',
    body: 'با فعالیت در تدارکس با مشتریان خود ارتباط برقرار کرده و دایره مشتریان خود را به صنایع گوناگون بسط دهید.',
  },
];
const title = 'مزایای پیوستن به اکوسیستم تدارکس برای شرکت ها';
export default function JoiningBenefit() {
  const id = localStorage.getItem('id');
  const [btnSize, setBtnSize] = useState('large');
  useEffect(() => {
    handleBtnSize();
  }, []);
  function handleBtnSize() {
    const width = window.innerWidth;
    if (width > 600) {
      setBtnSize('large');
    } else {
      setBtnSize('medium');
    }
  }
  function renderFarm() {
    return items.map((item, id) => {
      return (
        <li key={id} className="col-8 item flex flex-column gap-20">
          <div className="item-icon flex justify-center align-center">
            {item.icon}
          </div>
          <div className="item-title flex justify-center align-center">
            {item.title}
          </div>
          <div className="item-body flex justify-center align-center">
            <span>{item.body}</span>
          </div>
        </li>
      );
    });
  }
  return (
    <Style>
      <div className="container-m">
        <div className="section-title flex justify-center">
          <p>{title}</p>
        </div>
        <ul className="items flex justify-center gap-10">
          {renderFarm()}
        </ul>
        <div className="btn flex align-center justify-center">
          {id ? <Button href={`/submit/${id}`} size={btnSize} type='dark'>ثبت محصول | استعلام</Button> : <Button size={btnSize} type='dark'>ثبت نام در تدارکس</Button>}
        </div>
      </div>
      <div className="img"></div>
    </Style>
  );
}
import { Style } from "./style";
import PrimaryLayout from '../../Components/Layout/PrimaryLayout';
import HelmetFunction from '../../Components/Helmet';
import HeroSection from "../../Components/HeroSection";
import JoiningBenefit from "../../Components/JoiningBenefits";
import CompanySlider from './Companies';
import ProductSlider from "./Products";
import RfqSlider from './Rfqs';
import TadaroxHome from "./Us";


export default function HomePage() {
  return (
    <PrimaryLayout hbgcolor={'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)'}>
      <HelmetFunction title={'تدارکس بستری حرفه ای برای فعالیت فعالان صنعت کشور'} />
      <Style>
        <HeroSection />
        <JoiningBenefit />
        <CompanySlider />
        <ProductSlider />
        <TadaroxHome/>
        <RfqSlider />
      </Style>
    </PrimaryLayout>
  );
}
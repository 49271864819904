import { createGlobalStyle } from "styled-components";
import { pallete } from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  body {
    direction: rtl;
    font-family: 'Vazirmatn';
    background: #000;
  }
  a {
    text-decoration: none;
  }
  ul, ol {
    list-style: none;
  }
  /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px ${pallete.primary}; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${pallete.primary}; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${pallete.blue}; 
    }
  .container {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;
  }
  .container-l {
    width: 85%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .container-m {
    width: 75%;
    max-width: 850px;
    margin: 0 auto;
  }
  .container-s {
    width: 65%;
    margin: 0 auto;
  }
  .flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .nowrap {
    flex-wrap: nowrap;
  }
  .align-center {
    align-items: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-start {
    justify-content: start;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-evenly {
    justify-content: space-evenly;
  }
  .justify-center {
    justify-content: center;
  }
  .align-start {
    align-items: start;
  }
  .width-30 {
    width: 30%;
  }
  .gap-5 {
    gap: 5px;
  }
  .gap-10 {
    gap: 10px;
  }
  .gap-15 {
    gap: 15px;
  }
  .gap-20 {
    gap: 20px;
  }
  .gap-25 {
    gap: 25px;
  }
  .gap-50 {
    gap: 50px;
  }
  .mtb-5 {
    margin: 5px 0;
  }
  .mtb-10 {
    margin: 10px 0;
  }
  .mrl-5 {
    margin: 0 5px;
  }
  .mrl-10 {
    margin: 0 10px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .col-4 {
    width: 16.667%;
  }
  .col-8 {
    width: 33.333%;
  }
  .col-12 {
    width: 50%;
  }
  .col-16 {
    width: 66.667%;
  }
  .col-20 {
    width: 83.333%;
  }
  .col-24 {
    width: 100%;
  }
`;

export default GlobalStyle;
import { DatePicker, Form } from "antd";
import { Style } from "./style";

export default function Datepicker(props) {
  return (
    <Style>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={props.rules}
        dependencies={props.dependencies}
        hasFeedback={props.hasFeedback}>
        <DatePicker style={{ width: '100%' }} disabledDate={props.disabledDate} placeholder={props.placeholder} locale={props.locale}/>
      </Form.Item>
    </Style>
  );
}
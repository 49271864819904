import { Style } from "./style";
import PrimaryLayout from '../../../../Components/Layout/PrimaryLayout';
import HelmetFunction from '../../../../Components/Helmet';
import { useEffect, useState } from "react";
import { pallete } from "../../../../theme";
import Loading from "../../../../Components/Loading";
import { useSearchParams, createSearchParams, useParams } from "react-router-dom";
import { api } from "../../../../Utils/api";
import { Pagination } from "antd";
import { baseDirection } from "../../../../Utils/FileHandling";
import { ProductClass, ProductDescription } from "../../../../Components/Categories/ProductCategories";
import Button from '../../../../Components/uiElements/Button';




export default function CategorisedProducts() {
    const { category } = useParams();
    const [loading, setLoading] = useState(false);
    const desc = ProductDescription(category);
    const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
    const [msg, setMsg] = useState();
    const [data, setData] = useState({
        products: [],
        metadata: {
            total: 1,
            current_page: 1,
            per_page: 10,
        }
    });

    useEffect(() => {
        setLoading(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        getApi(searchParams.get('page', 'category'));
    }, [searchParams, category]);

    function getApi(page = 1, per_page = data.metadata.per_page) {
        api
            .get('categorised_products', { params: { page: page, per_page: per_page, q: `${category}` } })
            .then((response) => {
                setLoading(false);
                setData(response.data);
            })
            .catch((error) => {
                setMsg(error.response.data.message);
            });
    }

    function handleChange(page) {
        setSearchParams(createSearchParams({ page: page, per_page: data.metadata.per_page, q: category }));
    }
    function renderFarm() {
        return (
            data.products.map((item, index) => {
                return (
                    <li key={index} className="item">
                        <div className="flex flex-column gap-15">
                            <div className="box flex justify-between">
                                <div className="flex flex-column gap-5">
                                    <h1 className="item-title">{item.title}</h1>
                                    <div className="item-model flex align-center gap-5">
                                        <p className="item-model-t">مدل: </p>
                                        <p className="item-model-c">{item.model.length > 18 ? <>{item.model.substring(0, 18)} ...</> : item.model}</p>
                                    </div>
                                    <div className="item-category flex align-center gap-5">
                                        <p className="item-category-t">دسته بندی: </p>
                                        <p className="item-category-c">{<ProductClass category={item.category} />}</p>
                                    </div>
                                    {item.counterpart && <div className="item-counterpart flex align-center gap-5">
                                        <p className="item-counterpart-t">نمونه مشابه: </p>
                                        <p className="item-counterpart-c">{item.counterpart.length > 15 ? <>{item.counterpart.substring(0, 15)} ...</> : item.counterpart}</p>
                                    </div>}
                                    {item.producer_name && <div className="item-producer flex align-center gap-5">
                                        <p className="item-producer-t">تولید کننده: </p>
                                        <p className="item-producer-c">{item.producer_name}</p>
                                    </div>}
                                </div>
                                <div className="flex align-center">
                                    <img className="item-image" src={baseDirection.images.concat(item.image)} />
                                </div>
                            </div>
                            <div className="item-desc flex gap-5">
                                <p className="item-desc-t">شرح: </p>
                                <p className="item-desc-c">{item.description.length > 250 ? <>{item.description.substring(0, 250)} ...</> : item.description} </p>
                            </div>
                            <div className="item-detail flex align-center justify-center">
                                <Button type='dark' href={`/products/product/${item.id}`}>مشاهده جزئیات</Button>
                            </div>
                        </div>
                    </li>
                );
            })
        );
    }
    return (
        <PrimaryLayout hbgcolor={pallete.dark}>
            <HelmetFunction title='لیست محصولات' />
            <Style>
                <div className="container-m">
                    <div className="content flex flex-column align-center gap-50">
                        <div className="desc flex flex-column align-center gap-10">
                            <div>
                                <h3 className="desc-t">{desc.title}</h3>
                            </div>
                            <div className="flex flex-column gap-10">
                                <p className="desc-b">{desc.body[1]}</p>
                                <p className="desc-b">{desc.body[2]}</p>
                                <p className="desc-b">{desc.body[3]}</p>
                            </div>
                            <div className="flex align-center gap-5">
                                <img className="desc-img" src={desc.image} />
                            </div>
                        </div>
                        <ul className="items flex flex-column align-center gap-20">
                            {loading ? <Loading /> : renderFarm()}
                        </ul>
                        <Pagination showSizeChanger={false} style={{ direction: "ltr" }} pageSize={data.metadata.per_page} current={data.metadata.current_page} total={data.metadata.total} onChange={handleChange} />
                    </div>
                </div>
            </Style>
        </PrimaryLayout>
    );
}
import styled from "styled-components";
import { pallete } from "../../theme";

export const Style = styled.div`
padding: 90px 0;
background: ${pallete.borderColor};
.contact-top {
  .contact-top-icon {
    width: 25px;
    hieght: 25px;
    padding: 2px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid ${pallete.primary};
    background: ${pallete.blue};
  }
  .contact-top-h {
    font-size: 1.8rem;
    color: ${pallete.blue};
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: 2px solid ${pallete.primary};
  }
  .contact-top-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    line-height: 2.5;
  }
}
`;
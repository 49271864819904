import { Style } from "./style";
import { Form, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';


export default function UploadElement(props) {
  return (
    <Style>
      <Form.Item label={props.label}>
        <Form.Item name={props.name} valuePropName={props.name} getValueFromEvent={props.getValueFromEvent}>
          <Upload.Dragger maxCount={props.maxCount} name={props.name} beforeUpload={() => false} accept={props.accept}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint"></p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
    </Style>
  );
}
import HelmetFunction from '../../../Components/Helmet';
import Submit from '../../../Components/uiElements/Antd/Button';
import Email from '../../../Components/uiElements/Antd/Email';
import FormBuilder from '../../../Components/uiElements/Antd/Form';
import Password from '../../../Components/uiElements/Antd/Password';
import CheckBox from '../../../Components/uiElements/Antd/CheckBox';
import { Style } from './style';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { api } from '../../../Utils/api';
import { message } from 'antd';


export default function Login() {
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const id = localStorage.getItem('id');
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    const loggedInUser = (localStorage.getItem('access_token'));
    if (loggedInUser) {
      setAuthenticated(true);
    }
  }, []);
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);
  const onFinish = (values) => {
    api
      .post('/login', values, {
        Headers: {
          'Content-Type': 'application/json;Charset:UTF-8', "Access-Control-Allow-Origin": "*",
        }
      })
      .then((response) => {
        localStorage.setItem('access_token', response.data.token.access_token);
        localStorage.setItem('id', response.data.id);
        localStorage.setItem('username', response.data.username);
        setMsg(response.data.message);
        setAuthenticated(true);
      })
      .catch((error) => {
        setMsg(error.response.data.message);
      });
  };
  if (authenticated) {
    navigate(`/profile/${id}`);
  }
  return (
    <Style>
      <HelmetFunction title='ورود به حساب کاربری' />
      <FormBuilder name='login' onFinish={onFinish}>
        <Email />
        <Password hasFeedback={false} />
        {/* <CheckBox><span>مرا بخاطر بسپار</span></CheckBox> */}
        <Submit type={'primary'} htmlType={'submit'}>ورود به حساب کاربری</Submit>
      </FormBuilder>

    </Style>
  );
}
import { Form, Input } from "antd";
import { Style } from "./style";

export default function Email() {
  return (
    <Style>
      <Form.Item
        name='email'
        label='آدرس ایمیل'
        rules={[
          {
            required: true,
            message: 'آدرس ایمیل خود را وارد کنید'
          },
          {
            type: 'email',
            message: 'ایمیل وارد شده صحیح نمی باشد'
          }
        ]}>
        <Input showCount maxLength={40} />
      </Form.Item>
    </Style>
  );
}
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { Style } from './style';

export default function NotFound() {
    return (
        <Style>
            <Result
                status="404"
                title="404"
                subTitle="متاسفانه صفحه مورد نظر شما یافت نشد!"
                extra={<Link to={'/'}>
                    <Button size='large' type="primary">برگشت به صفحه اول</Button></Link>}
            />
        </Style>
    );
}
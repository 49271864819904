import { Fragment } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import ActionButton from "../../../Pages/UserProfile/FloatButton";
import ScrollToTop from "../../ScrollToTop";

export default function PrimaryLayout({ hbgcolor, children }) {
  return (
    <Fragment>
      <ScrollToTop />
      <Header hbgcolor={hbgcolor} />
      {children}
      <ActionButton />
      <Footer />
    </Fragment>
  );
}
import { Style } from "./style";
import { Form } from "antd";
import { useEffect } from "react";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
export default function FormBuilder(props) {
  const { name, onFinish, children, data, disabled } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    }
  }, [data]);
  return (
    <Style className="flex justify-center">
      <Form className="container-l"
        style={{
          width: '650px'
        }}
        {...formItemLayout}
        form={form}
        name={name}
        onFinish={onFinish}
        disabled={disabled}
        scrollToFirstError
      >
        {children}
      </Form>
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from '../../../theme';

export const Style = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 15;
  padding: 10px 0;
  backdrop-filter: blur(2px);
  .hamburger {
    color: ${pallete.primary};
    font-size: 2rem;
    padding: 4px;
    width: 30px;
    height: 30px;
    border: 2px solid ${pallete.primary};
    border-radius: 50%;
    transition: all 0.3s ease;
    &:hover {
        color: ${pallete.whiteColor1};
        border: 2px solid ${pallete.whiteColor1};
    }
    }
    .image {
        .logo {       
            font-size: 2rem;
            padding: 1px;
            width: 35px;
            height: 35px;
            border: 2px solid ${pallete.blue};
            border-radius: 50%;
        }
    }
    &.header-hide {
        transition: all 0.3s ease;
        top: -550px !important;
    }
@media only screen and ${device.md} {
    display: block;
}
`;
import PrimaryLayout from '../../../Components/Layout/PrimaryLayout';
import HelemetFunction from '../../../Components/Helmet';
import { pallete } from '../../../theme';
import { Style } from './style';
import CompanyInfo from './Info';
import CompanyProducts from './Products';
import { Fragment, useEffect, useState } from 'react';
import { api } from '../../../Utils/api';
import { useParams } from 'react-router-dom';
import Loading from '../../../Components/Loading';

export default function Company() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    id: '',
    username: '',
    avatar: '',
    about: '',
    expertise_1: '',
    expertise_2: '',
    expertise_3: '',
    expertise_4: '',
    expertise_5: '',
    expertise_6: '',
    need_1: '',
    need_2: '',
    need_3: '',
    tel: '',
    fax: '',
    address: '',
  });
  useEffect(() => {
    setLoading(true);
    getApi();
  }, []);
  function getApi() {
    api
      .get(`user/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelemetFunction title='صفحه شرکت در تدارکس' />
      <Style>
        {loading ? <Loading /> : <Fragment>
          <CompanyInfo data={data} />
          <CompanyProducts id={data.id} />
        </Fragment>}
      </Style>
    </PrimaryLayout>
  );
}
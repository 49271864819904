import { Style } from "./style";
import FormBuilder from '../../uiElements/Antd/Form';
import Selection from "../../uiElements/Antd/Select";
import { ProductCategories } from "../../Categories/ProductCategories";
import InputElement from '../../uiElements/Antd/Input';
import TextArea from '../../uiElements/Antd/TextArea';
import Inputnumber from '../../uiElements/Antd/InputNumber';
import { Currency } from '../../Categories/Currency';
import UploadElement from "../../uiElements/Antd/Upload";
import Submit from "../../uiElements/Antd/Button";
import { api } from "../../../Utils/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";


const categoryRules = [
  {
    required: true,
    message: 'دسته بندی محصول را انتخاب کنید',
  },
];
const titleRules = [
  {
    required: true,
    message: 'عنوان محصول را وارد نمایید!'
  }
];
const modelRules = [
  {
    required: true,
    message: 'مدل محصول را وارد نمایید!'
  }
];
const descRule = [
  {
    required: true,
    message: 'شرح محصول را وارد کنید',
  },
];
const currencyRules = [
  {
    required: true,
    message: 'واحد پولی را انتخاب نمایید!',
  },
];

export default function ProductSubmition() {
  const token = localStorage.getItem('access_token');
  const [msg, setMsg] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    model: "",
    description: "",
    couterpart: "",
    counterpart_description: "",
    price: "",
    currency: "",
    image: "",
    file: ""
  });
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.file;
    }
    return e && e.file;
  };
  const onFinish = (values) => {
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    api
      .post('/upload/product', values)
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        setMsg(error.message);
        console.log(error.message);
      });
  };
  if (!token) {
    navigate('/forbidden');
}
  return (
    <Style>
      <FormBuilder name='product' onFinish={onFinish}>
        <Selection name='category' label='دسته بندی محصول' rules={categoryRules}
          placeholder={'دسته بندی محصول'}
          options={ProductCategories} />
        <InputElement name='title' label='عنوان' rules={titleRules} showCount={true} maxLength={35} />
        <InputElement name='model' label='مدل' rules={modelRules} showCount={true} maxLength={35} />
        <TextArea name='description' label='شرح محصول' rules={descRule} showCount={true} maxLength={800} />
        <InputElement name='counterpart' label='نمونه مشابه' showCount={true} maxLength={35} />
        <TextArea name='counterpart_description' label='شرح نمونه مشابه' showCount={true} maxLength={800} />
        <Inputnumber name='price' label='قیمت محصول' maxLength={20} formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')} min={0} />
        <Selection name='currency' label='واحد پولی' rules={currencyRules}
          placeholder={'واحد پولی'}
          options={Currency} />
        <UploadElement name={'image'} label='آپلود عکس' getValueFromEvent={normFile} accept="image/png, image/jpeg, image/jpg image/svg" maxCount={1} />
        <UploadElement name={'file'} label='آپلود فایل' getValueFromEvent={normFile} accept=".pdf" maxCount={1} />
        <Submit type='primary' htmlType='submit'>ثبت محصول</Submit>
      </FormBuilder>
    </Style>
  );
}
import styled from "styled-components";
import { pallete } from '../../../theme';

export const Style = styled.div`
padding: 90px 0;
min-height: 100vh;
background-image: url('/asset/image/13.jpg');
background-size: cover;
background-position: center;
background-repeat: no-repeat;
background-attachment: fixed;
.content {
  min-height: 80vh;
  padding: 10px;
  border-radius: ${pallete.defaultRadius};
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
}
`;
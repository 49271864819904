import styled from "styled-components";
import { device, pallete } from "../../../theme";

export const Style = styled.div`
background: ${pallete.borderColor};
padding: 25px 0;
.b2b {
  padding: 2px;
  width: 100px;
  hieght: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid ${pallete.blue};
  background: ${pallete.whiteColor1};
}
.about-b {
  padding: 20px 0;
  font-size: 1.4rem;
  color: ${pallete.whiteColor1};
  line-height: 2.5;
}
.service {
  font-size: 1.4rem;
  color: ${pallete.whiteColor1};
  padding: 5px 0 10px 0;
}
.about-service-items {
  .item{
    .item-icon {
      color: ${pallete.primary};
      font-size: 1.4rem;
    }
    .item-t {
      font-weight: 600;
    }
    .item-t, .item-b {
      color: ${pallete.defaultBtnColor};
      font-size: 1.4rem;
      line-height: 2;
    }
  }
}
.plus {
  .plus-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    padding: 5px 0 10px 0;
  }
  .plus-items {
    .plus-item{
      .plus-item-icon {
        color: ${pallete.primary};
        font-size: 1.4rem;
      }
      .plus-item-t {
        font-weight: 600;
      }
      .plus-item-t, .plus-item-b {
        color: ${pallete.defaultBtnColor};
        font-size: 1.4rem;
        line-height: 2;
      }
    }
  }
}
@media only screen and ${device.sm} {
  .b2b {
    width: 80px;
    hieght: 80px;
  }
  .about-b {
    font-size: 1.3rem;
  }
  .service {
    font-size: 1.3rem;
  }
  .about-service-items {
    .item{
      .item-icon {
        font-size: 1.3rem;
      }
      .item-t, .item-b {
        font-size: 1.3rem;
      }
    }
  }
}
.plus {
  .plus-b {
    font-size: 1.3rem;
  }
  .plus-items {
    .plus-item{
      .plus-item-icon {
        font-size: 1.3rem;
      }
      .plus-item-t, .plus-item-b {
        font-size: 1.3rem;
      }
    }
  }
}
}
`
import styled from "styled-components";
import { pallete } from "../../../theme";

export const Style = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: none;
cursor: pointer;
height: ${(props) => (props.size === "large" ? "56px" : "40px")};
a {
  display: ${(props) => (props.block === "block" ? "block" : "flex")};
  justify-content: center;
  align-items: center;
  border-radius: ${pallete.defaultRadius};
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  font-weight: 600;
  font-size: ${(props) => (props.size === "large" ? "1.6rem" : "1.4rem")};
  line-height: 1.4rem;
  width: calc(100% - 8px);
  height: ${(props) => (props.size === "large" ? "48px" : "32px")};
  padding: ${(props) => (props.size === "large" ? "10px 20px" : "7px 10px")};
  border: 2px solid transparent;
  border: ${(props) => props.type === "primary" && "2px solid transparent"};
  border: ${(props) => (props.type === 'dark' && `2px solid ${pallete.darkBorder}`)};
  background: transparent;
  background: ${(props) => props.type === "primary" && pallete.primary};
  background: ${(props) => (props.type === 'dark' && pallete.darkButtonhover)};
  color: ${pallete.defaultBtnColor};
  color: ${(props) => (props.type === 'primary' && pallete.dark)};
  color: ${(props) => (props.type === 'dark' && pallete.primary)};
  &:hover {
    height: 100%;
    width: 100%;
    background: ${pallete.darkButtonhover};
    background: ${(props) => (props.type === 'primary' && pallete.primary)};
    background: ${(props) => (props.type === 'dark' && pallete.darkButtonhover)};
    border-color: ${pallete.darkBorder};
    border-color: ${(props) => (props.type === 'primary' && 'transparent')};
    border-color: ${(props) => (props.type === 'dark' && pallete.primary)};
  }
}
`;
import styled from "styled-components";
import { device, pallete } from "../../../../theme";

export const Style = styled.div`
width: 100%;
height: 500px;
margin-top: 15px;
background: ${pallete.borderColor};
  .title {
    color: ${pallete.defaultBtnColor};
    font-size: 2rem;
    padding: 10px 10px 10px 0;
  }
  .see-all {
    font-size: 1.2rem;
    padding: 10px 0 10px 10px;
    color: ${pallete.primary};
  }
  .swiper {
    width: 100%;
    height: 425px;
    .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 45%);
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 55%);  
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
  }

  .swiper-slide {
    text-align: center;
    padding: 10px;
    font-size: 10px;
    background: ${pallete.whiteColor1};

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    &.item {
      .box {
        .condition {
          background: ${pallete.darkButtonhover};
          border-radius: ${pallete.defaultRadius};
          padding: 10px;
          .img {
            width: 60px;
            height: 60px;
          }
          .text {
            font-size: 1.2rem;
            color: ${pallete.whiteColor1};
          }
        }
        .title {
          text-align: right;
          .title-c {
            font-size: 1.4rem;
            color: ${pallete.primary};
            font-weight: 700;
          }
        }
        .number {
          .number-t, .number-c, .number-h {
            font-size: 1.2rem;
            color: ${pallete.darkButtonhover};
            font-weight: 600;
          }
        }
        .quantity {
          .quantity-t, .quantity-c, .quantity-u {
            font-size: 1.2rem;
            color: ${pallete.darkButtonhover};
            font-weight: 600;
          }
        }
        .closing {
          .closing-t, .closing-c {
            font-size: 1.2rem;
            color: ${pallete.darkButtonhover};
            font-weight: 600;
          }
        }
        .delivery {
          .delivery-t, .delivery-c {
            font-size: 1.2rem;
            font-weight: 600;
            color: ${pallete.darkButtonhover};
          }
        }
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and ${device.md} {
    .title {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 300px) {
    .title {
      font-size: 1.4rem;
    }
  }
`;
import { Style } from "./style";
import { Form, Input } from "antd";

export default function TextArea(props) {
  return (
    <Style>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={props.rules}>
        <Input.TextArea showCount={props.showCount} maxLength={props.maxLength} />
      </Form.Item>
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../theme";

export const Style = styled.div`
&.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  padding: 15px 0;
  background: ${(props) => (props.hbgcolor ? props.hbgcolor : pallete.teal)};
  backdrop-filter: blur(2px);
  .content {
    .menu {
      .logo {
        .image {
          display: block;
          text-align: center;
          .img {
            display: block;
            width: auto;
            height: 30px;
          }
        }
      }
      .category {
        position: relative;
        .category-items {
          transition: all 0.5s ease;
          overflow: hidden;
          width: 250px;
          height: 0;
          position: absolute;
          z-index: 5;
          top: 100%;
          visibility: hidden;
          opacity: 0;
        }
        &:hover {
          .category-items {
            height: 410px;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .company {
        position: relative;
        .company-items {
          transition: all 0.5s ease;
          width: 250px;
          height: 0;
          overflow: hidden;
          position: absolute;
          z-index: 5;
          top: 100%;
          visibility: hidden;
          opacity: 0;
        }
        &:hover {
          .company-items {
            height: 125px;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  &.header-hide {
    transition: all 0.7s ease;
    top: -150px !important;
  }
  .action {
    .login {
      position: relative;
      .login-items {
        width: 150px;
        height: 0;
        overflow: hidden;
        position: absolute;
        z-index: 5;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        background: ${pallete.whiteColor1};
        border: 2px solid ${pallete.defaultBtnColor};
        border-radius: ${pallete.defaultRadius};
        transition: all 0.5s ease;
        padding: 5px;
        .btn {
          font-size: 1.2rem;
          font-weight: 500;
          transition: all 0.3s ease;
          padding: 5px;
          border: 2px solid ${pallete.primary};
          border-radius: ${pallete.defaultRadius};
          color: ${pallete.dark};
          &:hover {
            background: ${pallete.primary};
            border-color: ${pallete.dark};
          }
        }
      }
      &:hover {
        .login-items {
          height: 60px;
          visibility: visible;
          opacity: 1;
        }
    }
  }
}
@media only screen and ${device.lg} {
&.header {
  .content {
    flex-wrap: wrap;
    justify-content: center;
  }
}
}
@media only screen and ${device.md} {
&.header {
  display: none;
}
}
@media only screen and ${device.sm} {

}
@media only screen and ${device.xs} {

}
`;
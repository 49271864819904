import { Style } from './style';
import Tab from '../../Components/uiElements/Antd/Tab';
import Register from './Register';
import Login from './Login';
import PrimaryLayout from '../../Components/Layout/PrimaryLayout';
import ForgotPassword from './ForgotPassword';

const items = [
  {
    label: 'ثبت نام',
    key: 'register',
    children: <Register />
  },
  {
    label: 'ورود',
    key: 'login',
    children: <Login />
  },
  // {
  //   label: 'فراموشی رمز عبور',
  //   key: 'forgot-password',
  //   children: <ForgotPassword />
  // },
];

export default function Auth() {
  return (
    <PrimaryLayout hbgcolor={'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)'}>
      <Style>
        <div className='container-m'>
          <Tab className='content' animated={true} centered={true} type='card' items={items} />
        </div>
      </Style>
    </PrimaryLayout>
  );
}
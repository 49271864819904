import { Style } from "./style";
import RecentCompanies from "../../../Components/Slider/MultiItems/Companies";
import CompanyCarousel from "../../../Components/uiElements/Antd/Carousel/Companies";

export default function CompanySlider() {
  return (
    <Style>
      <RecentCompanies page={1} per_page={20} title={'شرکت های فعال در تدارکس'}/>
      {/* <CompanyCarousel page={1} per_page={20} title={'شرکت های فعال در تدارکس'} /> */}
    </Style>
  );
}
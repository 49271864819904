import Copyright from "./Copyright";
import FooterItems from "./Items";
import Social from "./Social";
import { Style } from "./style";

export default function Footer() {
  return (
    <Style className="flex flex-column gap-20 ptb-10">
      <FooterItems />
      <Social />
      <Copyright />
    </Style>
  );
}
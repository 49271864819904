import { useState, useEffect, Fragment } from "react";
import { Style } from "./style";
import { api } from "../../../Utils/api";
import { useParams } from "react-router-dom";
import AvatarElement from "../../../Components/uiElements/Antd/Avatar";
import Loading from '../../../Components/Loading';
import MyProducts from "./Products";

export default function UserInfo() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState({
    avatar: '',
    username: '',
    email: '',
    about: '',
    image: '',
    expertise_1: '',
    expertise_2: '',
    expertise_3: '',
    expertise_4: '',
    expertise_5: '',
    expertise_6: '',
    need_1: '',
    need_2: '',
    need_3: '',
    tel: '',
    fax: '',
    address: '',
  });
  useEffect(() => {
    setLoading(true);
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api.get(`profile/${id}`).then((response) => {
      setLoading(false);
      setData(response.data.user);
    }).catch((error) => {
      setLoading(false);
    });
  }, []);

  function handleExpertise() {
    return (
      <Fragment>
        {data.expertise_1 && <h3 className="expertise-title">تخصص ها</h3>}
        <div className="flex flex-column gap-5">
          {data.expertise_1 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_1}</span></p>}
          {data.expertise_2 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_2}</span></p>}
          {data.expertise_3 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_3}</span></p>}
          {data.expertise_4 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_4}</span></p>}
          {data.expertise_5 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_5}</span></p>}
          {data.expertise_6 && <p className="expertise-item flex align-center gap-5"><i className="expertise-icon fa-solid fa-circle-check"></i>
            <span>{data.expertise_6}</span></p>}
        </div>
      </Fragment>
    );
  }
  function handleNeed() {
    return (
      <Fragment>
        {data.need_1 && <h3 className="need-title">نیازهای کلیدی</h3>}
        <div className="flex flex-column gap-5">
          {data.need_1 && <p className="need-item flex align-center gap-5"><i className="need-icon fa-solid fa-circle-exclamation"></i>
            <span>{data.need_1}</span></p>}
          {data.need_2 && <p className="need-item flex align-center gap-5"><i className="need-icon fa-solid fa-circle-exclamation"></i>
            <span>{data.need_2}</span></p>}
          {data.need_3 && <p className="need-item flex align-center gap-5"><i className="need-icon fa-solid fa-circle-exclamation"></i>
            <span>{data.need_3}</span></p>}
        </div>
      </Fragment>
    );
  }
  function handleContact() {
    return (
      <Fragment>
        {data.tel && <h3 className="contact-title">اطلاعات تماس</h3>}
        {data.tel && <div className="contact-item flex align-center gap-5"><i className="contact-icon fa-solid fa-phone"></i><span>شماره تماس: </span>
          <span>{data.tel}</span>
        </div>}
        {data.fax && <div className="contact-item flex align-center gap-5"><i className="contact-icon fa-solid fa-fax"></i><span>فکس: </span>
          <span>{data.fax}</span>
        </div>}
        {data.address && <div className="contact-item flex align-center gap-5"><i className="contact-icon fa-solid fa-location-dot"></i><span>آدرس: </span>
          <span>{data.address}</span>
        </div>}
      </Fragment>
    );
  }
  function profileForm() {
    return (
      <Fragment>
        <div className="box flex align-center justify-evenly nowrap">
          <div className="avatar">
            {<AvatarElement image={(data.avatar)} username={data.username} />}
          </div>
          <h1 className="box-title">{data.username}</h1>
        </div>
        <div className="user-body flex flex-column gap-5">
          <h3 className="body-title">درباره ما</h3>
          <p className="body-content">{data.about}</p>
        </div>
        <div className="expertise flex flex-column gap-5">
          {handleExpertise()}
        </div>
        <div className="need flex flex-column gap-5">
          {handleNeed()}
        </div>
        <div className="contact flex flex-column gap-5">
          {handleContact()}
        </div>
      </Fragment>
    );
  }
  return (
    <Style >
      {loading ? <Loading /> : profileForm()}
      {/* <div className="products flex flex-column gap-10">
        <h3 className="product-title">محصولات ثبت شده در تدارکس</h3>
        <div style={{ width: '100%' }}>
          <MyProducts />
        </div>
      </div> */}
    </Style>
  );
}
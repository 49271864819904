import { Style } from "./style";
import CompanyRender from "./CompanyRender";
import ProductRender from "./ProductRender";
import RfqRender from './RfqRender';

export default function SearchResult(props) {
    const { result, category } = props;
    return (
        <Style data={result}>
            <ul className="flex flex-column gap-5">
                {category === 'company' && <CompanyRender data={result} />}
                {category === 'product' && <ProductRender data={result} />}
                {category === 'rfq' && <RfqRender data={result} />}
            </ul>
        </Style>
    );
}
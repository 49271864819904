import styled from "styled-components";
import { device, pallete } from "../../theme";

export const Style = styled.div`
padding: 20px 0;
.section-title {
  font-size: 2rem;
  color: ${pallete.defaultBtnColor};
  font-weight: 800;
}
.items {
  padding: 15px 0;
  color: ${pallete.dark};
  .item {
    min-height: 350px;
    background: ${pallete.whiteColor1};
    .item-icon {
      padding: 10px 0;
      img {
        width: 150px;
        height: 150px;
      }
    }
    .item-title {
      font-size: 1.6rem;
      font-weight: 700;
      padding: 10px 0;
      border-bottom: 2px solid ${pallete.primary};
    }
    .item-body {
      width: 85%;
      margin: 0 auto;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 10px 0;
      color: ${pallete.blue};
      padding-bottom: 25px;
    }
  }
}
.img {
  margin-top: 20px;
  width: 100%;
  height: 100px;
  background-image: url('/asset/image/2.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
@media only screen and ${device.md} {
  .items {
    .item {
      .item-icon {
        img {
          width: 120px;
          height: 120px;
        }
      }
      .item-title {
        font-size: 1.4rem;
      }
      .item-body {
        font-size: 1.2rem;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .items {
    flex-direction: column;
    gap: 15px;
    .item {
      width: 100%;
      .item-title {
        font-size: 2rem;
      }
      .item-body {
        font-size: 1.8rem;
      }
    }
  }
}
@media only screen and ${device.sm} {
  .section-title {
    text-align: center;
    font-size: 1.6rem;
  }
  .items {
    .item {
      .item-icon {
        img {
          width: 100px;
          height: 100px;
        }
      }
      .item-title {
        font-size: 1.6rem;
      }
      .item-body {
        font-size: 1.4rem;
      }
    }
  }
}
`;
import { useState, useEffect } from "react";
import { Style } from "./style";
import FormBuilder from "../../../Components/uiElements/Antd/Form";
import UploadElement from "../../../Components/uiElements/Antd/Upload";
import Submit from "../../../Components/uiElements/Antd/Button";
import { api } from "../../../Utils/api";
import { message } from "antd";


export default function SubmitInquiry(props) {
  const { rfq_id, currency, closing_date } = props;
  const token = localStorage.getItem('access_token');
  const [msg, setMsg] = useState('');
  const [data, setData] = useState({
    rfq_id: rfq_id,
    currency: currency,
    cost: 0,
    file: '',
    custom_id: 1
  });
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setData({ ...data, ['file']: e.file });
      return e;
    }
    setData({ ...data, ['file']: e.file });
    return e && e.fileList;

  };
  function onFinish() {
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    api
      .post('submit_inquiry', data)
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        setMsg(error.response.data.message);
      });
  }
  const moment = require('moment-jalaali');
  const today = moment(Date()).format('jYYYY/jMM/jDD');
  const close = moment(closing_date).format('jYYYY/jMM/jDD');
  const daysDiff = moment(close).diff(moment(today), 'days');
  console.log(data)
  return (
    <Style>
      <div className="container-m">
        <FormBuilder
          disabled={!token || daysDiff <= 0}
          name="submit_inquiry"
          onFinish={onFinish}
          scrollToFirstError
          className="submit_inquiry flex flex-column justify-center"
        >
          <UploadElement name={'file'} label="بارگذاری مستندات" getValueFromEvent={normFile} accept=".pdf" maxCount={1} />
          <div className="flex justify-center align-center">
            <Submit size='large' type='primary' htmlType='submit'>ثبت پیشنهاد</Submit>
          </div>
        </FormBuilder>
      </div>
    </Style>
  );
}
import styled from "styled-components";
import { pallete } from "../../../../theme";

export const Style = styled.div`
  .large-screen {
    display: block;
    .items {
      li:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .items {
      width: 100%;
      overflow: auto;
      .header {
        .header-item {
          font-size: 1.4rem;
          font-weight: 600;
          padding: 5px 0;
          border-bottom: 1px solid ${pallete.darkButtonhover};
          margin-bottom: 10px;
        }
      }
      .item {
        text-align: center;
        padding: 10px 0;
        .item-number {
          font-size: 1.2rem;
          color: ${pallete.dark};
        }
        .item-id {
          font-size: 1.2rem;
          color: ${pallete.dark};
        }
        .item-title {
          font-size: 1.2rem;
          color: ${pallete.dark};
        }
        .item-quantity {
          font-size: 1.2rem;
          color: ${pallete.dark};
        }
        .item-unit {
          font-size: 1.2rem;
          color: ${pallete.dark};
        }
        .item-detail {
          font-size: 1.2rem;
          color: ${pallete.primary};
          transition: all 0.3s ease;
          &:hover {
            font-weight: 700;
            color: ${pallete.borderColor};
          }
        }
      }
    }
  }
  .mobile {
    display: none;
    padding: 25px 0;
    background: transparent;
    .swiper {
      text-align: center;
      width: 265px;
      height: 350px;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: bold;
      color: ${pallete.dark};
      background: ${pallete.darkBorder};
      .item {
        width: 80%;
        border-radius: ${pallete.defaultRadius};
        box-shadow: 0 0 4px 4px ${pallete.primary};
        background: ${pallete.whiteColor1};
        padding: 20px;
        .image {
          .img {
            width: 45px;
            height: 45px;
          }
        }
        .item-id {
          font-size: 1.4rem;
          color: ${pallete.borderColor};
        }
        .item-title {
          padding: 10px 0;
          font-size: 1.2rem;
          color: ${pallete.primary};
          border-top: 2px solid ${pallete.whiteColor1};
          border-bottom: 2px solid ${pallete.whiteColor1};
        }
        .item-quantity {
          font-size: 1.4rem;
          color: ${pallete.dark};
        }
        .item-no {
          font-size: 1rem;
          color: ${pallete.borderColor};
          border-bottom: 1px dotted ${pallete.dark};
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .large-screen {
      .items {
        .header {
          .header-item {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .large-screen {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}
`;
export const DeliveryTerms = [
  {
    label: 'FAS',
    value: 'FAS'
  },
  {
    label: 'FOB',
    value: 'FOB'
  },
  {
    label: 'CFR',
    value: 'CFR'
  },
  {
    label: 'CIF',
    value: 'CIF'
  },
  {
    label: 'EXW',
    value: 'EXW'
  },
  {
    label: 'FCA',
    value: 'FCA'
  },
  {
    label: 'CPT',
    value: 'CPT'
  },
  {
    label: 'CIP',
    value: 'CIP'
  },
  {
    label: 'DAP',
    value: 'DAP'
  },
  {
    label: 'DDP',
    value: 'DDP'
  },
  {
    label: 'DPU',
    value: 'DPU'
  },
];
export const PaymentTerms = [
  {
    label: 'توافقی',
    value: 'توافقی',
  },
  {
    label: '100% پس از تحویل',
    value: '100% پس از تحویل',
  },
  {
    label: '50% پیش پرداخت',
    value: '50% پیش پرداخت',
  },
  {
    label: '25% پیش پرداخت',
    value: '25% پیش پرداخت',
  },
];
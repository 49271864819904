import styled from "styled-components";
import { pallete } from "../../../../theme";

export const Style = styled.li`
padding: 10px 0;
margin-bottom: 5px;
border-bottom: 2px dotted ${pallete.dark};
.result {
    width: 90%;
    .title {
        font-size: 1.6rem;
        color: ${pallete.dark};
        line-height: 1.5;
    }
    .body {
        font-size: 1.4rem;
        font-weight: 500;
        color: ${pallete.dark};
    }
}
.link {
    width: 10%;
    .detail {
        color: ${pallete.whiteColor1};
        font-size: 1.4rem;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
            color: ${pallete.primary};
    }
    }
}
`;
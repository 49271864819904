import { Avatar } from "antd";
import { Style } from "./style";
import { baseDirection } from '../../../../Utils/FileHandling';

export default function AvatarElement(props) {
  return (
    <Style>
      <Avatar src={props.image && <img src={baseDirection.images.concat(props.image)} />} >{!props.image && props.username.substring(0)}</Avatar>
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../../theme";

export const Style = styled.div`
padding: 80px 0;
width: 100%;
background: ${pallete.borderColor};
    .inner {
        padding: 30px;
        background: ${pallete.whiteColor1};
        .content {
            .title {
                .title-c {
                    font-size: 1.9rem;
                    color: ${pallete.primary};
                }
            }
            .model {
                .model-t {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
                .model-c {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
            }
            .category {
                .category-t {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
                .category-c {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
            }
            .producer {
                .producer-t {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
                .producer-c {
                    font-size: 1.4rem;
                    color: ${pallete.borderColor};
                }
            }
            .counterpart {
                .counterpart-t {
                    font-size: 1.4rem;
                    color: ${pallete.dark};
                }
                .counterpart-c {
                    font-size: 1.4rem;
                    color: ${pallete.dark};
                }
            }
            .price {
                .price-t {
                    font-size: 1.4rem;
                    color: ${pallete.dark};
                }
                .price-c {
                    font-size: 1.4rem;
                    color: ${pallete.dark};
                }
                .price-u {
                    font-size: 1.4rem;
                    color: ${pallete.dark};
                }
            }
            .image {
                .img {
                    width: 250px;
                    height: 250px;
                    object-fit: cover;
                    box-shadow: 0 0 4px ${pallete.defaultBtnColor};
                    // border-radius: 50%;
                    transition: all 1s ease;
                    &:hover {
                    //   transform: rotate(360deg) scale(1.15);
                      box-shadow: 0 0 4px ${pallete.primary};  
                    }
                }
            }
            .desc {
                .desc-m {
                    .desc-m-t {
                        font-size: 1.5rem;
                        color: ${pallete.dark};
                    }
                    .desc-m-c {
                        font-size: 1.3rem;
                        color: ${pallete.dark};
                    }
                }
                .desc-c {
                    .desc-c-t {
                        font-size: 1.4rem;
                        color: ${pallete.borderColor};
                    }
                    .desc-c-c {
                        font-size: 1.2rem;
                        color: ${pallete.borderColor};
                    }
                }
            }
            .download {
                margin: 10px 0;
                padding: 10px 15px;
            }
            
        }
    }
    @media only screen and ${device.lg} {
        padding: 150px 0;
    }
    @media only screen and ${device.md} {
        padding: 80px 0;
    }
    @media only screen and (max-width: 750px) {
        .inner {
            .content {
                .box {
                    flex-direction: column;
                    gap: 10px;
                    .image {
                        .img {
                            width: 200px;
                            height: 200px;
                        }
                    }
                    .title {
                        .title-c {
                            font-size: 1.7rem;
                        }
                    }
                    .model {
                        .model-t {
                            font-size: 1.2rem;
                        }
                        .model-c {
                            font-size: 1.2rem;
                        }
                    }
                    .category {
                        .category-t {
                            font-size: 1.2rem;
                        }
                        .category-c {
                            font-size: 1.4rem;
                        }
                    }
                    .producer {
                        .producer-t {
                            font-size: 1.2rem;
                        }
                        .producer-c {
                            font-size: 1.2rem;
                        }
                    }
                    .counterpart {
                        .counterpart-t {
                            font-size: 1.2rem;
                        }
                        .counterpart-c {
                            font-size: 1.2rem;
                        }
                    }
                    .price {
                        .price-t {
                            font-size: 1.2rem;
                        }
                        .price-c {
                            font-size: 1.2rem;
                        }
                        .price-u {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
    
}
`;
import styled from "styled-components";
import { pallete } from "../../../../../theme";

export const Style = styled.div`
background: ${pallete.darkButtonhover};
.title {
  font-size: 2rem;
  padding: 10px;
  color: ${pallete.whiteColor1};
}
.see-all {
  font-size: 1rem;
  padding: 10px;
  color: ${pallete.primary};
}
.item {
  min-height: 300px;
  padding: 20px 10px;
  background: ${pallete.whiteColor1};
  transition: all 0.3s ease;
  .item-img {
    width: 150px;
    height: 150px;
  }
  .item-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${pallete.dark};
    text-shadow: 0 0 1px ${pallete.primary};
  }
}
.ant-carousel {
  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          div {
            display: flex;
            gap: 5px;
            align-items: end;
          }
        }
      }
    }
  }
}
`;
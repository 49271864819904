import styled from "styled-components";
import { device, pallete } from "../../theme";

export const Style = styled.div`
background: ${pallete.dark};
padding: 90px 0;
.btn-menu {
  display: none;
}
.sidebar {
  background: ${pallete.darkBorder};
  position: sticky;
  top: 0;
  width: 250px;
  padding: 20px;
  margin-right: 20px;
  height: 100vh;
  border: 1px solid ${pallete.whiteColor1};
  border-radius: ${pallete.defaultRadius};
  .sidebar-inner {
    overflow: auto;
    .title {
      text-align: center;
      color: ${pallete.dark};
      font-size: 2.2rem;
    }
    .my-profile,
    .my-rfqs, 
    .my-inquiries,
    .recieved-inquiries,
    .edit-profile,
    .submit-rfq,
    .submit-product {
      font-size: 1.4rem;
      font-weight: 700;
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        color: ${pallete.primary};
      }
    }
  }
}
.content {
  min-height: 100vh;
  width: 100%;
  margin: 0 20px 0 40px;
  padding: 20px 0;
  background: ${pallete.whiteColor1};
  border: 1px solid ${pallete.darkButtonhover};
  border-radius: ${pallete.defaultRadius};
}
@media only screen and ${device.lg} {
  padding-top: 150px;
}
@media only screen and ${device.md} {
  padding-top: 90px;
  .btn-menu {
    display: block;
  }
  .sidebar {
    display: none;
    margin-right: 0;
  }
  .content {
    margin: 0;
  }
}
`
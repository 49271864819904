import { Style } from "./style";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from "react-router-dom";
import Loading from "../../../Loading";
import { useEffect, useState } from "react";
import { api } from "../../../../Utils/api";
import { ProductClass } from "../../../Categories/ProductCategories";
import { baseDirection } from "../../../../Utils/FileHandling";


export default function RecentProducts({ page, per_page, title }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [data, setData] = useState({
    products: [],
    metadata: {
      total: 1,
      per_page: per_page,
      current_page: page,
    },
  });
  useEffect(() => {
    getApi(page, per_page);
  }, []);
  function getApi(page = 1, per_page = data.metadata.per_page) {
    setLoading(true);
    api
      .get('products', { params: { page: page, per_page: per_page } })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error);
        console.log(error);
      });
  }
  function renderFarm() {
    return (
      data.products.map((item, index) => {
        return (
          <SwiperSlide className="item" key={index}>
            <Link to={`/products/product/${item.id}`}>
              <div className="flex flex-column gap-20">
                <div className="flex flex-column gap-5">
                  <h1 className="item-title">{item.title.length > 12 ? <>{item.title.substring(0, 12)} ...</> : item.title}</h1>
                  <div className="item-model flex align-center gap-5">
                    <p className="item-model-t">مدل: </p>
                    <p className="item-model-c">{item.model.length > 12 ? <>{item.model.substring(0, 12)} ...</> : item.model}</p>
                  </div>
                  <div className="item-counterpart flex align-center gap-5">
                    <p className="item-counterpart-t">نمونه مشابه: </p>
                    <p className="item-counterpart-c">{item.counterpart && (item.counterpart.length>12 ? <>{item.counterpart.substring(0, 12)} ...</> : item.counterpart)}</p>
                  </div>
                  <div className="item-category flex align-center gap-5">
                    <p className="item-category-t">دسته بندی: </p>
                    <p className="item-category-c">{<ProductClass category={item.category} />}</p>
                  </div>
                </div>
                <div className="flex justify-center align-center">
                  <img className="item-image" src={baseDirection.images.concat(item.image)} />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })
    );
  }
  return (
    <Style>
      <div className="flex align-center justify-between nowrap">
        <h3 className="title">{title}</h3>
        <Link to={'/products'} className="see-all flex align-center gap-5">
          <span>مشاهده همه</span>
          <i className="fa-solid fa-angles-left"></i>
        </Link>
      </div>
      <Swiper
        navigation
        slidesPerView={1}
        spaceBetween={5}
        modules={[Navigation]}
        breakpointsBase="window"
        breakpoints={{
          250: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          380: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          520: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          750: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 5,
          },
          1260: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        className="mySwiper"
      >
        {loading ? <Loading /> : renderFarm()}
      </Swiper>
    </Style>
  );
}
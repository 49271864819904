export const pallete = {
  primary: "#FC7300",
  dark: "#060606",
  borderColor: "#35363b",
  defaultBtnColor: "#f1dac4",
  whiteColor1: "#eaf6ff",
  defaultRadius: "10px",
  darkButtonhover: '#151515cc',
  darkBorder: '#eddecb',
  blue: '#3297DB',
  fontFamily: 'Vazirmatn',
};

export const size = {
  xs: "400px", // for small screen mobile
  sm: "600px", // for mobile screen
  md: "900px", // for tablets
  lg: "1280px", // for laptops
  xl: "1440px", // for desktop / monitors
  xxl: "1920px", // for big screens
};

export const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
};

import styled from "styled-components";
import { pallete } from '../../../../theme';


export const Style = styled.div`
position: fixed;
top: 60px;
right: 0;
max-height: 450px;
overflow: auto;
transition: all 0.8s ease;
.ant-menu-light {
    background: ${pallete.whiteColor1};
}
.ant-menu-submenu {
    .ant-menu-submenu-title {
        .ant-menu-item-icon, .ant-menu-title-content a {
            color: ${pallete.blue};
        }
    }
}
.ant-menu-item {
    height: 35px;
    .ant-menu-item-icon {
        color: ${pallete.blue};
    }
}
`;
import { Style } from "./style";
import PrimaryLayout from "../../../Components/Layout/PrimaryLayout";
import Tab from "../../../Components/uiElements/Antd/Tab";
import ProductSubmition from "../../../Components/SubmitionForms/Product";
import RfqSubmition from "../../../Components/SubmitionForms/Rfq";
import ProfileSubmition from "../../../Components/SubmitionForms/Profile";

const items = [
  {
    label: 'پروفایل',
    key: 'profile_edit',
    children: <ProfileSubmition />
  },
  {
    label: 'محصول',
    key: 'new_product',
    children: <ProductSubmition />
  },
  {
    label: 'استعلام',
    key: 'new_rfq',
    children: <RfqSubmition />
  },
];

export default function SubmitForm() {
  return (
    <PrimaryLayout hbgcolor={'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)'}>
      <Style>
        <div className='container-m'>
          <Tab className='content' animated={true} centered={true} type='card' items={items} />
        </div>
      </Style>
    </PrimaryLayout>
  );
}
import { Link } from "react-router-dom";
import { Style } from "./style";
import { Fragment } from "react";


export default function Button(props) {
  const {
    type = 'default',
    size = 'medium',
    icon,
    href,
    children,
    orientaition = 'row',
    className,
    target,
  } = props;

  function renderFarm() {
    if (icon && orientaition === 'row') {
      return (
        <div className="flex align-center gap-5">
          <span>
            <i className={icon}></i>
          </span>
          <span>{children}</span>
        </div>
      );
    } else if (icon && orientaition === 'column') {
      return (
        <div className="flex flex-column justify-center align-center gap-5">
          <span>
            <i className={icon}></i>
          </span>
          <span>{children}</span>
        </div>
      );
    } else {
      return (
        <Fragment>{children}</Fragment>
      );
    }
  }
  return (
    <Style type={type} size={size} icon={icon} {...props}>
      <Link className={className} to={href} target={target}>
        {renderFarm()}
      </Link>
    </Style>
  );
}
import styled from "styled-components";
import { pallete } from "../../../../theme";

export const Style = styled.div`
.ant-form-item {
  .ant-form-item-label {
    >label {
      color: ${pallete.darkButtonhover};
    }
  }
}
`;
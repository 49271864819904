import styled from "styled-components";
import { pallete } from "../../../../../theme";

export const Style = styled.div`
width: 100%;
margin: 15px 0;
background: ${pallete.borderColor};
.title {
  color: ${pallete.defaultBtnColor};
  font-size: 2rem;
  padding: 10px 10px 10px 0;
}
.see-all {
  padding: 10px 0 10px 10px;
  font-size: 1.2rem;
  color: ${pallete.primary};
}
.carousel-style {
  .ant-carousel {
    .slick-track {
      display: flex;
    }
  }
}
.item {
  position: relative;
  z-index: 2;
  padding: 10px;
  background: ${pallete.whiteColor1};
  .ant-avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 2px solid ${pallete.dark};
    margin-bottom: 5px;
  }
  .title {
    padding: 5px 0;
    font-size: 2rem;
    color: ${pallete.primary};
  }
  .contact-box {
    padding: 5px 0;
    margin-top: 5px;
    .tel, .fax {
      font-size: 1.4rem;
      color: ${pallete.dark}
    }
  }
}
`;
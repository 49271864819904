import { Style } from "./style";
import PrimaryLayout from '../../Components/Layout/PrimaryLayout';
import HelmetFunction from '../../Components/Helmet';
import { pallete } from "../../theme";
import { useState } from "react";
import Button from "../../Components/uiElements/Button";
import ProfileSubmition from "../../Components/SubmitionForms/Profile";
import RfqSubmition from "../../Components/SubmitionForms/Rfq";
import ProductSubmition from "../../Components/SubmitionForms/Product";
import UserInfo from "./Info";
import { useNavigate } from "react-router-dom";
import MyRfqs from "./Activities/MyRfqs";
import MyInquiries from "./Activities/MyInquiries";
import RecievedInquiries from "./Activities/RecievedInquiries";
import FloatBtn from "../../Components/uiElements/Antd/FloatBtn";
import { CommentOutlined, FormOutlined, UserOutlined, CustomerServiceOutlined } from '@ant-design/icons';


export default function Profile() {
  const [tab, setTab] = useState('my-profile');
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const username = localStorage.getItem('username');
  const items = [
    {
      icon: <i className="fa-solid fa-id-card-clip"></i>,
      tooltip: 'پروفایل',
      onClick: () => (setTab('my-profile')),
    },
    {
      icon: <i className="fa-regular fa-bell"></i>,
      tooltip: 'استعلام های من',
      onClick: () => (setTab('my-rfqs')),
    },
    {
      icon: <i className="fa-solid fa-arrow-right-from-bracket"></i>,
      tooltip: 'پیشنهادهای من',
      onClick: () => (setTab('my-inquiries')),
    },
    {
      icon: <i className="fa-brands fa-get-pocket"></i>,
      tooltip: 'پیشنهادهای دریافتی',
      onClick: () => (setTab('recieved-inquiries')),
    },
    {
      icon: <i className="fa-solid fa-user-pen"></i>,
      tooltip: 'ویرایش پروفایل',
      onClick: () => (setTab('edit-profile')),
    },
    {
      icon: <i className="fa-solid fa-arrow-up-right-from-square"></i>,
      tooltip: 'ثبت استعلام',
      onClick: () => (setTab('submit-rfq')),
    },
    {
      icon: <i className="fa-solid fa-seedling"></i>,
      tooltip: 'ثبت محصول جدید',
      onClick: () => (setTab('submit-product')),
    },
  ];
  function handleContent() {
    let result;
    switch (tab) {
      case 'my-profile':
        // profile info from api and display in content;
        result = <UserInfo />;
        break;
      case 'my-rfqs':
        // my submitted rfqs info from api and display in content
        result = <MyRfqs />;
        break;
      case 'my-inquiries':
        // my submitted inquiries from api and display in content
        result = <MyInquiries />;
        break;
      case 'recieved-inquiries':
        // inquiries of my submitted rfqs from api and display in content
        result = <RecievedInquiries />;
        break;
      case 'edit-profile':
        // recieve info of my profile from api and edit it and sumbit it again to api
        result = <ProfileSubmition />;
        break;
      case 'submit-rfq':
        // form of rfq submition
        result = <RfqSubmition />;
        break;
      case 'submit-product':
        // form of product submition
        result = <ProductSubmition />;
        break;

    }
    return result;
  }
  if (!token) {
    navigate('/');
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='پروفایل من' />
      <Style>
        <div className="btn-menu">
          <FloatBtn items={items} type={'primary'} shape='square' trigger='click' right={14} bottom={15} icon={<i className="fa-solid fa-list-check"></i>} />
        </div>
        <div className="flex justify-between gap-20">
          <div className="sidebar flex align-center">
            <div className="sidebar-inner flex flex-column justify-center gap-5">
              <h1 className="title">{username}</h1>
              <div className="my-profile">
                <Button type='dark' onClick={() => (setTab('my-profile'))}><span>مشاهده پروفایل</span></Button>
              </div>
              <div className="my-rfqs">
                <Button type='dark' onClick={() => (setTab('my-rfqs'))}><span>استعلام های من</span></Button>
              </div>
              <div className="my-inquiries">
                <Button type='dark' onClick={() => (setTab('my-inquiries'))}><span>پیشنهادهای من</span></Button>
              </div>
              <div className="recieved-inquiries">
                <Button type='dark' onClick={() => (setTab('recieved-inquiries'))}><span>پیشنهادهای دریافتی</span></Button>
              </div>
              <div className="edit-profile">
                <Button type='dark' onClick={() => (setTab('edit-profile'))}><span>ویرایش پروفایل</span></Button>
              </div>
              <div className="submit-rfq">
                <Button type='dark' onClick={() => (setTab('submit-rfq'))}><span>ثبت استعلام</span></Button>
              </div>
              <div className="submit-product">
                <Button type='dark' onClick={() => (setTab('submit-product'))}><span>ثبت محصول جدید</span></Button>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-l">
              <div className="content-inner">
                {handleContent()}
              </div>
            </div>
          </div>
        </div>
      </Style>
    </PrimaryLayout>
  );
}
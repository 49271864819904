export const CompanyCategories = [
  {
    label: 'شرکت های برتر',
    value: 'top_companies',
    icon: <i className="fa-solid fa-ranking-star"></i>,
    url: '/companies',
    subitems: [],
  },
  {
    label: 'شرکت های تولید کننده',
    value: 'suppliers',
    icon: <i className="fa-solid fa-parachute-box"></i>,
    url: '/companies',
    subitems: [],
  },
  {
    label: 'شرکت های مصرف کننده',
    value: 'consumers',
    icon: <i className="fa-solid fa-download"></i>,
    url: '/companies',
    subitems: [],
  },
];
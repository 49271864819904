import styled from "styled-components";
import { pallete } from "../../theme";

export const Style = styled.div`
    padding: 80px 0;
    background-image: url('asset/image/4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    .box {
        position: relative;
        backdrop-filter: blur(15px);
        z-index: 1;
        padding: 10px;
        background: transparent;
        border: 2px solid ${pallete.whiteColor1};
        border-radius: 50px;
        .select {
            width: 20%;
            border: 2px solid ${pallete.whiteColor1};
            border-radius: 25px;
            transition: all 0.3s ease;
            .ant-select-selector {
                border-radius: 25px;
            }
            &:hover {
                border-color: ${pallete.dark};
            }
        }
        .search {
            width: 80%;
            border: 2px solid ${pallete.whiteColor1};
            border-radius: 25px;
            transition: all 0.3s ease;
            &:hover {
                border-color: ${pallete.dark};
            }
        }
    }
@media only screen and (max-width: 1100px) {
    padding-top: 150px;
}
@media only screen and (max-width: 750px) {
    padding-top: 80px;
    .box {
        flex-direction: column;
        border: none;
        .select {
            width: 95%;
            height: 30px;
            .ant-select-selector {
                font-size: 1.2rem;
            }
        }
        .search {
            font-size: 1.2rem;
            width: 95%;
            height: 30px;
        }
    }
}
`;
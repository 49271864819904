import { Style } from "./style";
import Button from '../uiElements/Button';
import { useEffect, useState } from "react";
import { size } from "../../theme";


export default function HeroSection() {
  const token = localStorage.getItem('access_token');
  const id = localStorage.getItem('id');
  const [btnSize, setBtnSize] = useState('large');
  useEffect(() => {
    handleSize();
    handleSwitch();
  }, []);
  function handleSize() {
    const width = window.innerWidth;
    if (width > 600) {
      setBtnSize('large');
    } else {
      setBtnSize('medium');
    }
  }
  function handleSwitch() {
    let btn;
    if (token) {
      btn = (<Button href={`/profile/${id}`} type="dark" size={btnSize}>مدیریت کارتابل</Button>);
    } else {
      btn = (<Button href={'/auth'} size={btnSize} type='dark'>ثبت نام در تدارکس</Button>);
    }
    return btn;
  }
  return (
    <Style>
      <div className="container-m">
        <div className="content">
          <div className="flex flex-column justify-center align-center gap-20">
            <div className="logo flex align-center justify-center">
              <img className="image" src="asset/image/logo.svg" />
            </div>
            <h1 className="hero-title">صنعت ملی ایران</h1>
            <h4 className="hero-body">بستری برای ارتباط مستقیم فعالان صنعت کشور</h4>
            <h3 className="hero-account">فعالیت خود را در تدارکس آغاز کنید</h3>
            <div className="btn">
              {handleSwitch()}
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../../../theme";

export const Style = styled.div`
padding: 25px 0;
width: 100%;
height: 500px;
margin: 15px 0;
background: ${pallete.borderColor};
  .title {
    color: ${pallete.defaultBtnColor};
    font-size: 2rem;
    padding: 10px 10px 10px 0;
  }
  .see-all {
    font-size: 1.2rem;
    padding: 10px 0 10px 10px;
    color: ${pallete.primary};
  }
  .swiper {
    width: 100%;
    height: 350px;
    .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 45%);
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 55%);  
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
  }

  .swiper-slide {
    text-align: center;
    padding: 10px;
    font-size: 10px;
    background: ${pallete.whiteColor1};

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    &.item {
      .item-title {
        font-size: 1.4rem;
        text-align: start;
        color: ${pallete.primary};
      }
      .item-model {
        .item-model-t, .item-model-c {
          font-size: 1.2rem;
          font-weight: 600;
          color: ${pallete.dark};
        }
      }
      .item-counterpart {
        .item-counterpart-t, .item-counterpart-c {
          font-size: 1.2rem;
          font-weight: 600;
          color: ${pallete.dark};
        }
      }
      .item-category {
        .item-category-t, .item-category-c {
          font-size: 1.2rem;
          font-weight: 600;
          color: ${pallete.dark};
        }
      }
      .item-counterpart {
        .item-counterpart-t, .item-counterpart-c {
          font-size: 1.2rem;
          color: ${pallete.primary};
          font-weight: 600;
        }
      }
      .item-producer {
        .item-producer-t, .item-producer-c {
          font-size: 1.2rem;
          font-weight: 600;
          color: ${pallete.dark};
        }
      }
      .item-image {
        width: 150px;
        height: 150px;
        text-align: center;
      }
    }
    .item-desc {
      .item-desc-t, .item-desc-c {
        font-size: 1.2rem;

      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and ${device.md} {
    .title {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 310px) {
    .title {
      font-size: 1.4rem;
    }
  }
`;
import { Form, Input } from "antd";
import { Style } from "./style";

export default function Password(props) {
  return (
    <Style>
      <Form.Item name='password'
        label='گذرواژه'
        rules={[
          {
            required: true,
            message: 'گذرواژه را وارد نمایید'
          },
        ]}
        hasFeedback={props.hasFeedback}>
        <Input.Password showCount maxLength={24}/>
      </Form.Item>
    </Style>
  );
}
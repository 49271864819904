import { Link, useParams } from "react-router-dom";
import { Style } from "./style";

export default function FooterItems() {
  const id = localStorage.getItem('id');
  return (
    <Style className="flex justify-evenly ptb-10 mtb-10">
      <div className="col-8 flex flex-column align-center gap-5">
        <h1 className="title">تدارکس</h1>
        <Link className="item" to={'/aboutus'}>
          <p>درباره ما</p>
        </Link>
        <Link className="item" to={'/contactus'}>
          <p>تماس با ما</p>
        </Link>
      </div>
      <div className="col-8 flex flex-column align-center gap-5">
        <h1 className="title">بخش های اصلی</h1>
        <Link className="item" to={'/companies'}>
          <p>شرکت ها</p>
        </Link>
        <Link className="item" to={'/products'}>
          <p>محصولات</p>
        </Link>
        <Link className="item" to={'/rfqs'}>
          <p>استعلام ها</p>
        </Link>
      </div>
      <div className="col-8 flex flex-column align-center gap-5">
      <h1 className="title">اقدامات</h1>
        <Link className="item" to={'/auth'}>
          <p>ثبت نام در تدارکس</p>
        </Link>
        <Link className="item" to={'/auth'}>
          <p>ورود به حساب کاربری</p>
        </Link>
        <Link className="item" to={id ? `/submit/${id}`:'/auth'}>
          <p>ثبت استعلام در تدارکس</p>
        </Link>
      </div>
    </Style>
  );
}
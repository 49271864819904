import { Style } from "./style";

export default function TadaroxHome() {
  return (
    <Style>
      <div className="container-l">
        <div className="flex justify-center align-center gap-50">
          <img className="b2b" src="/asset/image/b2b.png" />
        </div>
        <p className="about-b">پلتفرم  تدارکس یک بازار B2B آنلاین است که به شرکت‌های بالادستی در صنایع نفت، گاز و پتروشیمی، فولاد، مواد معدنی، سیمان و سایر صنایع مرتبط، امکان ارتباط و همکاری با تامین کنندگان داخلی جهت رفع نیاز کالاها و خدمات درخواستی را می‌دهد. این پلتفرم با ارائه خدمات متنوعی مانند جستجوی محصولات و خدمات، امکان جستجوی کالای وارداتی و مشاهده تولیدکنندگان داخلی، مقایسه قیمت‌ها، مدیریت سفارشات و پرداخت‌ها، امکان داخلی سازی محصول مورد نیاز و .... به شرکت‌ها کمک می‌کند تا فرآیند خرید خود را بهینه‌سازی کرده و هزینه‌های خود را کاهش دهند.</p>
        <p className="service">پلتفرم تدارکس خدمات متنوعی را به شرکت‌های بالادستی ارائه می‌دهد که از جمله آنها می‌توان به موارد زیر اشاره کرد:</p>
        <ul className="about-service-items flex flex-column gap-10">
          <li className="item">
            <div className="flex align-center gap-5">
              <i className="item-icon fa-solid fa-plus"></i>
              <span className="item-t">جستجوی محصولات و خدمات</span>
            </div>
          </li>
          <li className="item">
            <div className="flex align-center gap-5">
              <i className="item-icon fa-solid fa-plus"></i>
              <span className="item-t">مقایسه قیمت ها</span>
            </div>
          </li>
          <li className="item">
            <div className="flex align-center gap-5">
              <i className="item-icon fa-solid fa-plus"></i>
              <span className="item-t">مدیریت سفارشات</span>
            </div>
          </li>
          <li className="item">
            <div className="flex align-center gap-5">
              <i className="item-icon fa-solid fa-plus"></i>
              <span className="item-t">پرداخت ها</span>
            </div>
          </li>
        </ul>
        <div className="plus">
          <p className="plus-b">استفاده از پلتفرم تدارکس مزایای متعددی برای شرکت‌های بالادستی دارد که از جمله آنها می‌توان به موارد زیر اشاره کرد:</p>
          <ul className="plus-items flex flex-column gap-10">
            <li className="plus-item">
              <div className="flex align-center gap-5">
                <i className="plus-item-icon fa-solid fa-plus"></i>
                <span className="plus-item-t">کاهش هزینه ها</span>
              </div>
            </li>
            <li className="plus-item">
              <div className="flex align-center gap-5">
                <i className="plus-item-icon fa-solid fa-plus"></i>
                <span className="plus-item-t">افزایش بهره وری</span>
              </div>
            </li>
            <li className="plus-item">
              <div className="flex align-center gap-5">
                <i className="plus-item-icon fa-solid fa-plus"></i>
                <span className="plus-item-t">دسترسی به بازارهای جدید</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Style>
  );
}
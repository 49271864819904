import { Style } from "./style";
import PrimaryLayout from '../../Components/Layout/PrimaryLayout';
import { pallete } from "../../theme";
import HelmetFunction from '../../Components/Helmet';
import { Input, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef } from "react";
import SearchResult from "./Result";
import { api } from "../../Utils/api";
import { createSearchParams, useSearchParams } from "react-router-dom";

const options = [
    {
        value: 'company',
        label: 'شرکت ها',
    },
    {
        value: 'product',
        label: 'محصولات'
    },
    {
        value: 'rfq',
        label: 'استعلام ها',
    },
];

export default function SearchPage() {
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [category, setCategory] = useState(searchParams.get('category'),);
    useEffect(() => {
        if (searchParams.get('q')) {
            getApi(searchParams.get('q'));
        }
    }, []);
    function getApi(search) {
        switch (searchParams.get('category')) {
            case 'company':
                api
                    .get('search_users', { params: { q: search } })
                    .then((response) => {
                        setData(response.data.result);
                    })
                    .catch((error) => {
                    });
                break;
            case 'product':
                api
                    .get('search_products', { params: { q: search } })
                    .then((response) => {
                        setData(response.data.result);
                    })
                    .catch((error) => {
                    });
                break;
            case 'rfq':
                api
                    .get('search_rfq', { params: { q: search } })
                    .then((response) => {
                        setData(response.data.result);
                    })
                    .catch((error) => {
                    });
                break;
        }
    }
    const handleChange = (value) => {
        setCategory(value);
        setSearchParams(createSearchParams({ category: value }));
        setData([]);
    };

    function handleSearch(event) {
        const { value } = event.target;
        getApi(value);
        setSearchParams(createSearchParams({ q: value, category: category }));
    }

    function handleSearchPlaceholder() {
        switch (searchParams.get('category')) {
            case 'company':
                return 'مشخصات شرکت را وارد کنید';
            case 'product':
                return 'مشخصات محصول را وارد کنید';
            case 'rfq':
                return 'مشخصات استعلام را وارد کنید';
            default:
                return 'جستجو';
        }
    }
    console.log(searchParams.get('category'));
    return (
        <PrimaryLayout hbgcolor={pallete.dark}>
            <HelmetFunction title={'جستجو در تدارکس'} icon={'/asset/image/fvicon.svg'} />
            <Style>
                <div className="container-l">
                    <div className="box flex align-center justify-center gap-10">
                        <Select defaultValue={searchParams.get('category') && searchParams.get('category')} placeholder='محدوده جستجو' size="large" className="select"
                            options={options}
                            onChange={handleChange} />
                        <Input prefix={<SearchOutlined />} className="search" allowClear size="large" name="search" placeholder={handleSearchPlaceholder()} onChange={handleSearch} value={searchParams.get('q') && searchParams.get('q')} />
                    </div>
                </div>
                <div className="container">
                    <SearchResult className='output' result={data} category={category} />
                </div>
            </Style>
        </PrimaryLayout>
    );
}
import { Style } from "./style";
import FormBuilder from '../../uiElements/Antd/Form';
import Selection from "../../uiElements/Antd/Select";
import { Units } from "../../Categories/Units";
import InputElement from '../../uiElements/Antd/Input';
import TextArea from '../../uiElements/Antd/TextArea';
import Inputnumber from '../../uiElements/Antd/InputNumber';
import { Currency } from '../../Categories/Currency';
import UploadElement from "../../uiElements/Antd/Upload";
import Submit from "../../uiElements/Antd/Button";
import { PaymentTerms } from '../../Categories/PaymentTerms';
import { DeliveryTerms } from '../../Categories/DeliveryTerms';
import Datepicker from "../../uiElements/Antd/Datepicker";
import locale from 'antd/es/date-picker/locale/fa_IR';
import 'dayjs/locale/fa';
import moment from "moment";
import { api } from "../../../Utils/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const titleRules = [
  {
    required: true,
    message: 'عنوان استعلام را وارد نمایید.'
  }
];
const quantityRules = [
  {
    required: true,
    message: 'مقدار و یا تعداد را وارد نمایید.'
  }
];
const unitRules = [
  {
    required: true,
    message: 'واحد را انتخاب نمایید.'
  }
];
const descRule = [
  {
    required: true,
    message: 'شرح استعلام را وارد نمایید',
  },
];
const currencyRules = [
  {
    required: true,
    message: 'واحد پولی را انتخاب نمایید!',
  },
];
const paymentRules = [
  {
    required: true,
    message: 'نحوه پرداخت را انتخاب نمایید.'
  }
];
const deliveryRules = [
  {
    required: true,
    message: 'شرایط تحویل را انتخاب نمایید.'
  }
];
const locationRules = [
  {
    required: true,
    message: 'آدرس محل تحویل را مشخص نمایید.'
  }
];
const certificateRules = [
  {
    required: true,
    message: 'نیاز و یا عدم نیاز به ارائه گواهی را مشخص نمایید.'
  }
];
const certificateOptions = [
  {
    label: 'بله',
    value: 'yes'
  },
  {
    label: 'خیر',
    value: 'no'
  },
];
const closingRules = [
  {
    required: true,
    message: 'تاریخ سر رسید ارسال پیشنهاد را درج نمایید.'
  }
];
const delivertTimeRules = [
  {
    required: true,
    message: 'لطفا تاریخ تحویل را تعیین نمایید.'
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('closing_date') <= value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('تاریخ تحویل نباید از تاریخ سر رسید ارسال پیشنهاد کوچکتر باشد!'));
    },
  }),
];

export default function RfqSubmition() {
  const moment = require('moment-jalaali');
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.file;
    }
    return e && e.file;
  };
  function onFinish(values) {
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    api
      .post('/submit_rfq', values)
      .then((response) => {
        setMsg(response.data.message);
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setMsg(error.response.data.message);
      });
  }
  if (!token) {
    navigate('/forbidden');
  }
  return (
    <Style>
      <FormBuilder name='submit_rfq' onFinish={onFinish}>
        <InputElement name='title' label='عنوان' rules={titleRules} showCount={true} maxLength={35} />
        <InputElement name='quantity' label='مقدار | تعداد' rules={quantityRules} showCount={true} maxLength={10} />
        <Selection name='unit' label='واحد' rules={unitRules}
          placeholder={'واحد'}
          options={Units} />
        <TextArea name='description' label='شرح استعلام' rules={descRule} showCount={true} maxLength={800} />
        <Inputnumber name='budget' label='بودجه' showCount={true} maxLength={20} formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')} min={0} />
        <Selection name='currency' label='واحد پولی' rules={currencyRules}
          placeholder={'واحد پولی'}
          options={Currency} />
        <Selection name='payment_term' label='شرایط پرداخت' rules={paymentRules}
          placeholder={'نحوه پرداخت'}
          options={PaymentTerms} />
        <Selection name='delivery_term' label='شرایط تحویل' rules={deliveryRules}
          placeholder={'شرایط تحویل'}
          options={DeliveryTerms} />
        <TextArea name='delivery_location' label='آدرس محل تحویل' rules={locationRules} showCount={true} maxLength={200} />
        <Selection name='certificate' label='گواهی نامه' rules={certificateRules}
          placeholder={'نیاز به ارائه گواهی دارد؟'}
          options={certificateOptions} />
        <Datepicker name='closing_date' label='سر رسید ارسال پیشنهاد' disabledDate={(current) => {
          return moment().add(-1, 'days') >= current;
        }} locale={locale} rules={closingRules} placeholder='انتخاب تاریخ' hasFeedback={true} />
        <Datepicker name='delivery_time' label='تاریخ تحویل' dependencies={['closing_date']} disabledDate={(current) => {
          return moment().add(-1, 'days') >= current;
        }} locale={locale} rules={delivertTimeRules} placeholder='انتخاب تاریخ' />
        <TextArea name='other' label='توضیحات تکمیلی' showCount={true} maxLength={800} />
        <UploadElement name={'file'} label='آپلود فایل' getValueFromEvent={normFile} accept=".pdf" maxCount={1} />
        <Submit type='primary' htmlType='submit'>ثبت استعلام</Submit>
      </FormBuilder>
    </Style>
  );
}
import styled from "styled-components";
import { pallete } from "../../../theme";

export const Style = styled.div`
.box {
  padding: 10px 5px;
  margin: 20px 0;
  border: 1px solid ${pallete.borderColor};
  border-radius: ${pallete.defaultRadius};
  .ant-avatar {
    width: 65px;
    height: 65px;
    display: flex;
    border: 1px solid ${pallete.borderColor};
    align-items: center;
    background: ${pallete.defaultBtnColor};
    .ant-avatar-string {
      font-size: 1.8rem;
      color: ${pallete.dark};
    }
  }
}
.box-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: ${pallete.dark};
}
}
.user-body {
  padding: 10px 5px;
  margin: 20px 0;
  border: 1px solid ${pallete.borderColor};
  border-radius: ${pallete.defaultRadius};
  .body-title {
    font-size: 1.6rem;
    color: ${pallete.dark};
  }
  .body-content {
    font-size: 1.3rem;
    color: ${pallete.dark};
  }
}
.expertise {
  padding: 10px 5px;
  margin: 20px 0;
  border: 1px solid ${pallete.borderColor};
  border-radius: ${pallete.defaultRadius};
  .expertise-title {
    font-size: 1.6rem;
    color: ${pallete.dark};
  }
  .expertise-item {
    font-size: 1.3rem;
    color: ${pallete.dark};
    .expertise-icon {
      color: ${pallete.primary};
    }
  }
}
.need {
  padding: 10px 5px;
  margin: 20px 0;
  border: 1px solid ${pallete.borderColor};
  border-radius: ${pallete.defaultRadius};
  .need-title {
    font-size: 1.6rem;
    color: ${pallete.dark};
  }
  .need-item {
    font-size: 1.3rem;
    color: ${pallete.dark};
    .need-icon {
      color: ${pallete.primary};
    }
  }
}
.contact {
  padding: 10px 5px;
  margin: 20px 0;
  border: 1px solid ${pallete.borderColor};
  border-radius: ${pallete.defaultRadius};
  .contact-title {
    font-size: 1.6rem;
    color: ${pallete.dark};
  }
  .contact-item {
    font-size: 1.3rem;
    color: ${pallete.dark};
    .contact-icon {
      color: ${pallete.primary};
    }
  }
}
.products {
  margin-top: 40px;
  .product-title {
    font-size: 1.6rem;
    padding: 10px 0;
    border-bottom: 2px solid ${pallete.primary};
  }
  .swiper {
    .swiper-slide {
      background: ${pallete.whiteColor1};
      &.item {
        .item-title {
          font-size: 1.4rem;
        }
        .item-model {
          font-size: 1rem;
        }
        .item-category {
          display: none;
        }
        .item-counterpart {
          font-size: 1.2rem;
        }
      }
    }
  }
}
`;
import { Style } from "./style";
import { Select, Form } from "antd";

const { Option } = Select;

export default function Selection(props) {
  function handleOptions() {
    return (props.options.map((item, index) => {
      return (
        <Option key={index} value={item.value}>{item.label}</Option>
      );
    }));
  }
  return (
    <Style>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={props.rules}
        required={props.required}
        >
        <Select onChange={props.onChange} defaultValue={props.defaultValue} placeholder={props.placeholder}>
          {handleOptions()}
        </Select>
      </Form.Item>
    </Style>
  );
}
import { Form, Checkbox } from "antd";
import { Style } from "./style";


const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function CheckBox(props) {
  return (
    <Style>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={props.rules}
        {...tailFormItemLayout}>
        <Checkbox>
          {props.children}
        </Checkbox>
      </Form.Item>
    </Style>
  );
}
export const Currency = [
  {
    label: 'IRR',
    value: 'IRR',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'AED',
    value: 'AED',
  },
  {
    label: 'CNY',
    value: 'CNY',
  },
];

import styled from "styled-components";
import { pallete } from "../../../theme";

export const Style = styled.div`
// background-image: url('/asset/image/4.jpg');
// background-position: center;
// background-size: cover;
// background-repeat: no-repeat;
// background-attachment: fixed;
background: ${pallete.borderColor};
`;
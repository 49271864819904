import { Form, Input } from "antd";
import { Style } from "./style";

export default function ConfirmPassword(props) {
  return (
    <Style>
      <Form.Item name='password_confirm'
        label='تائید گذرواژه'
        rules={[
          {
            required: true,
            message: 'گذرواژه خود را تائید نمایید'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('تکرار گذرواژه صحیح نمی باشد!'));
            },
          }),
        ]}
        dependencies={props.dependencies}
        hasFeedback
      >
        <Input.Password showCount maxLength={24}/>
      </Form.Item>
    </Style>
  );
}
import styled from "styled-components";
import { pallete } from "../../../theme";

export const Style = styled.div`
  .item {
    a {
      i {
        font-size: 1.8rem;
        color: ${pallete.whiteColor1};
        transition: all 0.3s ease-in-out;
        &:hover {
          color: ${pallete.primary};
          }
        }
    }
  }
`;
import { Link } from "react-router-dom";
import { Style } from "./style";
import { useState } from "react";

export default function Menu({ menuData, dir }) {
  // State variable to hold the currently hovered item
  const [hoveredItem, setHoveredItem] = useState(1);

  // Function to handle hover event on first-level menu items
  const handleHover = (item) => {
    setHoveredItem(item);
  };

  // Function to render the second-level menu based on hovered item
  const renderSubmenu = () => {
    if (hoveredItem && menuData[hoveredItem - 1].subitems.length > 0) {
      const subitems = menuData[hoveredItem - 1].subitems;
      return (
        <ul className="second-level">
          {subitems.map((subitem) => (
            <li key={subitem.value}
              className="second-level-item">{subitem.label}</li>
          ))}
        </ul>
      );
    }
    return null;
  };
  return (
    <Style className="flex justify-between" dir={dir}>
      {/* First-level menu */}
      <ul className="first-level">
        {menuData.map((item, id) => (
          <li key={id}
            onMouseOver={() => handleHover(id + 1)}
            className="first-level-item">
            <Link className="flex align-center justify-between" to={item.url}>
              <div className="flex align-center gap-5">
                <span className="icon">{item.icon}</span>
                <span>{item.label}</span>
              </div>
              {item.subitems.length > 0 && <i className={dir === 'ltr' ? 'fa-solid fa-angle-right' : 'fa-solid fa-angle-left'}></i>}
            </Link>
          </li>
        ))}
      </ul>

      {/* Second-level menu */}
      {renderSubmenu()}
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../../theme";

export const Style = styled.div`
padding: 90px 0;
background: ${pallete.borderColor};
.content {
  width: 100%;
  padding: 30px;
  background: ${pallete.whiteColor1};
  .content-inner {
    .title {
      .title-c {
        font-size: 2.2rem;
        color: ${pallete.primary};
        font-weight: 800;
      }
    }
    .id {
      .id-t, .id-c, .id-prefix {
        font-size: 1.4rem;
        color: ${pallete.dark};
        font-weight: 600;
      }
    }
    .quantity {
      .quantity-t, .quantity-c, .quantity-u {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .closing {
      .closing-t, .closing-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .delivery {
      .delivery-t, .delivery-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .delivery-term {
      .delivery-term-t, .delivery-term-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .payment {
      .payment-t, .payment-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .currency {
      .currency-t, .currency-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .certificate {
      .certificate-t, .certificate-c {
        font-size: 1.4rem;
        color: ${pallete.borderColor};
        font-weight: 600;
      }
    }
    .condition {
      width: 260px;
      background: ${pallete.defaultBtnColor};
      border: 2px solid ${pallete.dark};
      border-radius: ${pallete.defaultRadius};
      padding: 5px;
      .img {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
      .text {
        font-size: 1.2rem;
        color: ${pallete.dark};
        font-weight: 700;
      }
    }
    .desc {
      .desc-c {
        font-size: 1.4rem;
        color: ${pallete.dark};
        font-weight: 600;
      }
    }
    .other {
      .other-t, .other-c {
        font-size: 1.4rem;
        color: ${pallete.dark};
      }
    }
    .location {
      .location-t,  {
        font-size: 1.2rem;
        color: ${pallete.dark};
        font-weight: 600;
      }
      .location-c {
        font-size: 1.2rem;
        color: ${pallete.dark};
      }
    }
  }
}
@media only screen and ${device.lg} {
  padding-top: 150px;
}
@media only screen and ${device.md} {
  padding-top: 90px;
  .content {
    .content-inner {
      .top {
        gap: 20px;
        flex-direction: column-reverse;
        .title {
          .title-c {
            font-size: 1.8rem;
          }
        }
        .condition {
          text-align: center;
        }
      }
    }
  }
}
@media only screen and ${device.sm} {
  .content {
    .content-inner {
      .title {
        .title-c {
          font-size: 1.6rem;
        }
      }
      .id {
        .id-t, .id-c, .id-prefix {
          font-size: 1.2rem;
        }
      }
      .quantity {
        .quantity-t, .quantity-c, .quantity-u {
          font-size: 1.2rem;
        }
      }
      .closing {
        .closing-t, .closing-c {
          font-size: 1.2rem;
        }
      }
      .delivery {
        .delivery-t, .delivery-c {
          font-size: 1.2rem;
        }
      }
      .delivery-term {
        .delivery-term-t, .delivery-term-c {
          font-size: 1.2rem;
        }
      }
      .payment {
        .payment-t, .payment-c {
          font-size: 1.2rem;
        }
      }
      .currency {
        .currency-t, .currency-c {
          font-size: 1.2rem;
        }
      }
      .certificate {
        .certificate-t, .certificate-c {
          font-size: 1.2rem;
        }
      }
      .condition {
        .img {
          width: 50px;
          height: 50px;
        }
        .text {
          font-size: 1rem;
        }
      }
      .desc {
        .desc-c {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
      .other {
        .other-t, .other-c {
          font-size: 1.2rem;
        }
      }
      .location {
        .location-t,  {
          font-size: 1rem;
        }
        .location-c {
          font-size: 1rem;
        }
      }
    }
  }
}
`;
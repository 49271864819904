import styled from "styled-components";
import { pallete } from "../../theme";

export const Style = styled.div`
padding: 90px 0;
background: ${pallete.borderColor};
.about-top {
  .about-top-icon {
    width: 20px;
    hieght: 20px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid ${pallete.primary};
    background: ${pallete.blue};
  }
  .about-top-h {
    font-size: 1.8rem;
    color: ${pallete.blue};
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: 2px solid ${pallete.primary};
  }
  .about-top-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    line-height: 2.5;
  }
}
.about-service {
  .about-service-t {
    font-size: 1.8rem;
    padding: 10px 0;
    color: ${pallete.blue};
  }
  .about-service-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    padding: 5px 0 10px 0;
  }
  .about-service-items {
    .item{
      .item-icon {
        color: ${pallete.primary};
        font-size: 1.4rem;
      }
      .item-t {
        font-weight: 600;
      }
      .item-t, .item-b {
        color: ${pallete.defaultBtnColor};
        font-size: 1.4rem;
        line-height: 2;
      }
    }
  }
}
.plus {
  .plus-t {
    font-size: 1.8rem;
    padding: 10px 0;
    color: ${pallete.blue};
  }
  .plus-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    padding: 5px 0 10px 0;
  }
  .plus-items {
    .plus-item{
      .plus-item-icon {
        color: ${pallete.primary};
        font-size: 1.4rem;
      }
      .plus-item-t {
        font-weight: 600;
      }
      .plus-item-t, .plus-item-b {
        color: ${pallete.defaultBtnColor};
        font-size: 1.4rem;
        line-height: 2;
      }
    }
  }
}
.perspective {
  .perspective-t {
    font-size: 1.8rem;
    padding: 10px 0;
    color: ${pallete.blue};
  }
  .perspective-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    padding: 5px 0 10px 0;
  }
}
.conclusion {
  .conclusion-t {
    font-size: 1.8rem;
    padding: 10px 0;
    color: ${pallete.blue};
  }
  .conclusion-b {
    font-size: 1.4rem;
    color: ${pallete.whiteColor1};
    padding: 5px 0 10px 0;
  }
}
`;
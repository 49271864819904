import { Link } from "react-router-dom";
import { Style } from "./style";


export default function CompanyRender({ data }) {
    return (
        data.map((item, index) => {
            return (
                <Style key={index} className="flex justify-between align-center gap-10">
                    <div className="result flex flex-column nowrap gap-5">
                        <h3 className="title">{item.username && item.username}</h3>
                        <p className="body">{item.about && item.about.substring(0, 100)} ...</p>
                    </div>
                    <div className="link flex align-center justify-center">
                        <Link className="detail" target="_blank" to={`/companies/company/${item.id}`}>مشاهده</Link>
                    </div>
                </Style>
            );
        })
    );
}
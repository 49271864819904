import { Style } from "./style";
import { FloatButton } from "antd";


export default function FloatBtn(props) {
  const token = localStorage.getItem('access_token');
  const id = localStorage.getItem('id');
  if (!token) {
    return;
  }
  function handleBtns() {
    return (
      props.items.map((item, index) => {
        return (
          <FloatButton key={index}
            icon={item.icon && item.icon}
            tooltip={item.tooltip && item.tooltip}
            badge={item.badge && item.badge}
            onClick={item.onClick && item.onClick}>
            {item.children && item.children}
          </FloatButton>
        );
      })
    );
  }
  return (
    <Style>
      <FloatButton.Group
        trigger={props.trigger}
        type={props.type}
        style={{ left: props.left && props.left, bottom: props.bottom && props.bottom, right: props.right && props.right, top: props.top && props.top }}
        icon={props.icon}
        shape={props.shape && props.shape}>
        {handleBtns()}
      </FloatButton.Group>
    </Style>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../theme";

export const Style = styled.div`
width: 100%;
height: 700px;
text-align: center;
background-image: url('asset/image/2.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
.content {
  position: relative;
  z-index: 1;
  padding: 150px 0;
  color: ${pallete.whiteColor1};
  .logo {
    .image {
      width: 200px;
      height: auto;
    }
  }
  .hero-title {
    font-size: 4rem;
    font-weight: 800;
  }
  .hero-body {
    font-size: 3rem;
    font-weight: 800;
  }
  .hero-account {
    font-size: 3.5rem;
    font-weight: 800;
  }
}
@media only screen and ${device.md} {
.content {
  .logo {
    .image {
      width: 180px;
    }
  }
  .hero-title {
    font-size: 3.5rem;
  }
  .hero-body {
    font-size: 2.4rem
  }
  .hero-account {
    font-size: 3rem;
  }
}
}
@media only screen and ${device.sm} {
  .content {
    .logo {
      .image {
        width: 160px;
      }
    }
    .hero-title {
      font-size: 2.8rem;
    }
    .hero-body {
      font-size: 1.8rem
    }
    .hero-account {
      font-size: 2.2rem;
    }
  }
}
@media only screen and ${device.xs} {
  .content {
    .logo {
      .image {
        width: 140px;
      }
    }
    .hero-title {
      font-size: 2.5rem;
    }
    .hero-body {
      font-size: 1.4rem
    }
    .hero-account {
      font-size: 1.8rem;
    }
  }
}
`;
import { Style } from "./style";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from "react-router-dom";
import Loading from "../../../Loading";
import { useEffect, useState } from "react";
import AvatarElement from "../../../uiElements/Antd/Avatar";
import { pallete } from "../../../../theme";
import { api } from "../../../../Utils/api";


export default function RecentCompanies({ page, per_page, title }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [data, setData] = useState({
    users: [],
    metadata: {
      total: 1,
      per_page: per_page,
      current_page: page,
    },
  });
  useEffect(() => {
    getApi(page, per_page);
  }, []);
  function getApi(page = 1, per_page = data.metadata.per_page) {
    setLoading(true);
    api
      .get('ordered_users', { params: { page: page, per_page: per_page } })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error);
      });
  }
  function renderFarm() {
    return (
      data.users.map((item, index) => {
        return (
          <SwiperSlide className="item" key={index}>
            <Link to={`/companies/company/${item.id}`}>
              <div className="flex flex-column gap-10">
                <div className="inner-content">
                  <AvatarElement username={item.username} image={item.avatar} />
                  <h1 className="title">{item.username}</h1>
                  <div className="desc flex justify-start">
                    <p className="about">{item.about && <>{item.about.substring(0, 80)}<>... </></>}</p>
                  </div>
                  <div className="exp-box flex flex-column">
                    <div className="flex justify-start">
                      <h3 className="exp-t">تخصص</h3>
                    </div>
                    <div>
                      <div className="exp-item flex align-center gap-5">
                        {item.expertise_1 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i><span>{item.expertise_1.substring(0, 20)} ...</span></>}
                      </div>
                      {/* <div className="exp-item flex align-center gap-5">
                        {item.expertise_2 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                          <span>{item.expertise_2.substring(0, 20)} ...</span></>}
                      </div>
                      <div className="exp-item flex align-center gap-5">
                        {item.expertise_3 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                          <span>{item.expertise_3.substring(0, 20)} ...</span></>}
                      </div> */}
                    </div>
                  </div>
                  <div className=" need-box flex flex-column">
                    <div className="flex justify-start">
                      <h3 className="need-t">نیاز کلیدی</h3>
                    </div>
                    <div className="need-item flex align-center gap-5">
                      {item.need_1 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_1.substring(0, 20)} ...</span></>}
                    </div>
                    {/* <div className="need-item flex align-center gap-5">
                      {item.need_2 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_2.substring(0, 20)} ...</span></>}
                    </div>
                    <div className="need-item flex align-center gap-5">
                      {item.need_3 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_3.substring(0, 20)} ...</span></>}
                    </div> */}
                  </div>
                  <div className=" contact-box flex flex-column">
                    <div className="flex justify-start">
                      <h3 className="contact-t">اطلاعات تماس</h3>
                    </div>
                    <div className="tel flex align-center gap-5">
                      {item.tel && <><i className="fa-solid fa-phone" style={{ color: `${pallete.primary}` }}></i><span>شماره تماس: </span><span>{item.tel}</span></>}
                    </div>
                    <div className="fax flex align-center gap-5">
                      {item.fax && <><i className="fa-solid fa-fax" style={{ color: `${pallete.primary}` }}></i><span>شماره فکس: </span><span>{item.fax}</span></>}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })
    );
  }
  return (
    <Style>
      <div className="flex align-center justify-between nowrap">
        <h3 className="title">{title}</h3>
        <Link to={'/companies'} className="see-all flex align-center gap-5">
          <span>مشاهده همه</span>
          <i className="fa-solid fa-angles-left"></i>
        </Link>
      </div>
      <Swiper
        navigation
        slidesPerView={1}
        spaceBetween={5}
        modules={[Navigation]}
        breakpointsBase="window"
        breakpoints={{
          250: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          520: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          750: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 5,
          },
          1260: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        className="mySwiper"
      >
        {loading ? <Loading /> : renderFarm()}
      </Swiper>
    </Style>
  );
}
import { useEffect } from "react";

export default function RemoveUerInfo() {
  useEffect(() => {
    const removeLoginInfo = () => {
      localStorage.removeItem('username');
      localStorage.removeItem('access_token');
      localStorage.removeItem('id');
    };
    window.addEventListener('beforeunload', removeLoginInfo);
    return () => {
      window.removeEventListener('beforeunload', removeLoginInfo);
    };
  }, []);
}

import { Button, Form } from 'antd';
import { Style } from './style';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function Submit(props) {
  const { children,
          type = 'primary',
          htmlType,
        } = props;
  return (
    <Style>
      <Form.Item {...tailFormItemLayout}>
        <Button type={type} htmlType={htmlType} {...props}>{children}</Button>
      </Form.Item>
    </Style>
  );
}
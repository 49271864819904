import { Form, InputNumber } from "antd";
import { Style } from "./style";

export default function Inputnumber(props) {
  return (
    <Style>
      <Form.Item
        name={props.name}
        label={props.label}>
        <InputNumber
          maxLength={props.maxLength}
          formatter={props.formatter}
          parser={props.parser}
          style={{ width: '100%' }}
          min={props.min}
        />
      </Form.Item>
    </Style>
  );
}
import { Style } from "./style";
import { Link } from "react-router-dom";

export default function ProductRender({ data }) {
    return (data.map((item, index) => {
        return (
            <Style key={index} className="flex justify-between align-center gap-10">
                <div className="result flex flex-column flex-nowrap gap-5">
                    <h3 className="title">{item.title && item.title}</h3>
                    <p className="body">{item.description && item.description.substring(0, 100)} ...</p>
                </div>
                <div className="link flex align-center justify-center">
                    <Link className="detail" target="_blank" to={`/products/product/${item.id}`}>مشاهده</Link>
                </div>
            </Style>
        );
    }));
}
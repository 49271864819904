import styled from "styled-components";
import { device, pallete } from "../../../../theme";

export const Style = styled.div`
width: 100%;
height: 500px;
margin: 15px 0;
background: ${pallete.borderColor};
  .title {
    color: ${pallete.defaultBtnColor};
    font-size: 2rem;
    padding: 10px 10px 10px 0;
  }
  .see-all {
    padding: 10px 0 10px 10px;
    font-size: 1.2rem;
    color: ${pallete.primary};
  }
  .swiper {
    width: 100%;
    height: 425px;
    .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 45%);
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 20px);
      left: auto;
      top: var(--swiper-navigation-top-offset, 55%);  
      border: 2px solid transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${pallete.defaultBtnColor};
      color: ${pallete.dark};
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid ${pallete.darkButtonhover};
      }    
      &:after {
        font-size: 1.6rem;
      }   
    }
  }

  .swiper-slide {
    text-align: center;
    padding: 10px;
    font-size: 10px;
    background: ${pallete.whiteColor1};
    // min-height: 350px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    &.item {
      .inner-content {
        .ant-avatar {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border: 2px solid ${pallete.dark};
          margin-bottom: 5px;
        }
        .title {
          padding: 5px 0;
          font-size: 1.4rem;
          color: ${pallete.primary};
          margin-bottom: 5px;
        }
        .desc {
          padding: 5px 0;
          margin-bottom: 5px;
          .about {
            text-align: right;
            font-size: 1rem;
            color: ${pallete.dark};
          }
        }
        .exp-box {
          padding: 5px 0;
          margin-top: 5px;
          .exp-t {
            font-size: 1.1rem;
          }
          .exp-item {
            font-size: 1rem;
          }
        }
        .need-box {
          padding: 5px 0;
          margin-top: 5px;
          .need-t {
            font-size: 1.1rem;
          }
          .need-item {
            font-size: 1rem;
          }
        }
        .contact-box {
          padding: 5px 0;
          margin-top: 5px;
          .tel, .fax {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and ${device.md} {
    .title {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 300px) {
    .title {
      font-size: 1.4rem;
    }
  }
`;
import styled from "styled-components";
import { pallete } from "../../../theme";

export const Style = styled.div`
    color: ${pallete.dark};
    border: ${(props) => (props.data.length > 0 && `1px solid ${pallete.dark}`)};
    border-radius: ${(props) => (props.data.length > 0 && pallete.defaultRadius)}; 
    position: relative;
    z-index: 1;
    height: 85vh;
    overflow: auto;
    padding: 25px 20px;
    margin-top: 10px;
    background: ${(props) => (props.data.length > 0 && 'rgba(255, 255, 255, 0.4)')};
    backdrop-filter: ${(props) => (props.data.length > 0 && 'blur(20px)')};
`;
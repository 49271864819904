import { Style } from "./style";
import FormBuilder from '../../uiElements/Antd/Form';
import InputElement from '../../uiElements/Antd/Input';
import TextArea from '../../uiElements/Antd/TextArea';
import UploadElement from "../../uiElements/Antd/Upload";
import Submit from "../../uiElements/Antd/Button";
import Email from "../../uiElements/Antd/Email";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from '../../Loading';
import { api } from '../../../Utils/api';
import { message } from 'antd';


const usernameRules = [
  {
    required: true,
    message: 'نام کاربری خود را وارد کنید'
  },
];
const descRule = [
  {
    required: true,
    message: 'شناخت شما مایه مسرت ماست!',
  },
];
const expertiseRules = [
  {
    required: true,
    message: 'مهم ترین توانایی شرکت خود را وارد نمایید!',
  },
];
const needRules = [
  {
    required: true,
    message: 'مهم ترین نیاز شرکت خود را وارد نمایید!',
  },
];

export default function ProfileSubmition() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('access_token');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    avatar: '',
    username: '',
    email: '',
    about: '',
    image: '',
    expertise_1: '',
    expertise_2: '',
    expertise_3: '',
    expertise_4: '',
    expertise_5: '',
    expertise_6: '',
    need_1: '',
    need_2: '',
    need_3: '',
    tel: '',
    fax: '',
    address: '',
    file: '',
  });

  useEffect(() => {
    setLoading(true);
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api
      .get(`/profile/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.user);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg("");
    }
  }, [msg]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.file;
    }
    return e && e.file;
  };

  const onFinish = (values) => {
    const access = localStorage.getItem('access_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    api
      .put('/update_profile', values)
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        setMsg(error.message);
      });
  };
  function handleResult() {
    return (
      <FormBuilder data={data} name='submitprofile' onFinish={onFinish}>
        <InputElement disabled={true} name='username' label='نام کاربری' rules={usernameRules} showCount={true} maxLength={30} />
        <Email />
        <TextArea name='about' label='درباره ما' rules={descRule} showCount={true} maxLength={800} />
        <InputElement name='expertise_1' label='تخصص اصلی' rules={expertiseRules} showCount={true} maxLength={50} />
        <InputElement name='expertise_2' label='تخصص دوم' showCount={true} maxLength={50} />
        <InputElement name='expertise_3' label='تخصص سوم' showCount={true} maxLength={50} />
        <InputElement name='expertise_4' label='تخصص چهارم' showCount={true} maxLength={50} />
        <InputElement name='expertise_5' label='تخصص پنجم' showCount={true} maxLength={50} />
        <InputElement name='expertise_6' label='تخصص ششم' showCount={true} maxLength={50} />
        <InputElement name='need_1' label='نیاز کلیدی اول' rules={needRules} showCount={true} maxLength={50} />
        <InputElement name='need_2' label='نیاز کلیدی دوم' showCount={true} maxLength={50} />
        <InputElement name='need_3' label='نیاز کلیدی سوم' showCount={true} maxLength={50} />
        <InputElement name='tel' label='شماره تماس' showCount={true} maxLength={14} />
        <InputElement name='fax' label='شماره فکس' showCount={true} maxLength={14} />
        <TextArea name='address' label='آدرس' showCount={true} maxLength={200} />
        <UploadElement name={'file'} label='آپلود لوگو | تصویر' getValueFromEvent={normFile} accept="image/png, image/jpg, image/jpeg, image/svg" maxCount={1} />
        <Submit type='primary' htmlType='submit'>ثبت اطلاعات</Submit>
      </FormBuilder>
    );
  }
  if (!token) {
    navigate('/');
  }
  return (
    <Style>
      {loading ? <Loading /> : handleResult()}
    </Style>
  );
}
import { Link } from "react-router-dom";
import { Style } from "./style";
import { MenuOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";

export default function ResponsiveHeader(props) {
  const { hbgcolor } = props;
  const [top, setTop] = useState(false);
  const [scroll, setScroll] = useState(window.scrollY);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleTop();
  }, [scroll]);
  function handleTop() {
    if (window.scrollY - 10 > scroll) {
      setScroll(window.scrollY);
      setTop(true);
    } else if (window.scrollY < scroll) {
      setScroll(window.scrollY);
      setTop(false);
    }
  }
  window.addEventListener('scroll', handleTop);
  function handleClick() {
    setOpen(!open);
  }
  return (
    <Style className={top ? 'responsive header-hide' : 'responsive'} style={{ background: hbgcolor }}>
      <div className="container">
        <div className="flex align-center justify-between">
          <MenuOutlined onClick={handleClick} className="hamburger flex align-center justify-center" />
          <Link className="image" to={'/'}><img className="logo" src="/asset/image/fvicon.svg" /></Link>
        </div>
        <Sidebar open={open} onClickOutside={() => { setOpen(false); }} />
      </div>
    </Style>
  );
}
import { Style } from "./style";
import { pallete } from "../../../../theme";
import AvatarElement from "../../../../Components/uiElements/Antd/Avatar";

export default function CompanyInfo({ data }) {
  return (
    <Style>
      <div className="container-l">
        <div className="flex flex-column gap-10 align-center">
          <div className="content">
          <div className="flex flex-column gap-10 align-center">
              <div className="inner-content">
                <div className="top flex align-center justify-between">
                  <h1 className="title">{data.username}</h1>
                  <AvatarElement className="avatar" username={data.username} image={data.avatar} />
                </div>
                <div className="desc flex align-center justify-center">
                  <p className="about">{data.about && <>{data.about}</>}</p>
                </div>
                <div className="exp-box flex flex-column">
                  <div>
                    <h3 className="exp-t">تخصص ها</h3>
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_1 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i><span>{data.expertise_1}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_2 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{data.expertise_2}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_3 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{data.expertise_3}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_4 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{data.expertise_4}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_5 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{data.expertise_5}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {data.expertise_6 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{data.expertise_6}</span></>}
                  </div>
                </div>
                <div className=" need-box flex flex-column">
                  <div>
                    <h3 className="need-t">نیازهای کلیدی</h3>
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {data.need_1 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{data.need_1}</span></>}
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {data.need_2 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{data.need_2}</span></>}
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {data.need_3 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{data.need_3}</span></>}
                  </div>
                </div>
                <div className=" contact-box flex flex-column">
                  <div>
                    <h3 className="contact-t">اطلاعات تماس</h3>
                  </div>
                  <div className="tel flex align-center gap-5">
                    {data.tel && <><i className="fa-solid fa-phone" style={{ color: `${pallete.primary}` }}></i><span>شماره تماس: </span><span>{data.tel}</span></>}
                  </div>
                  <div className="fax flex align-center gap-5">
                    {data.fax && <><i className="fa-solid fa-fax" style={{ color: `${pallete.primary}` }}></i><span>شماره فکس: </span><span>{data.fax}</span></>}
                  </div>
                  <div className="address flex align-center gap-5">
                    {data.address && <><i className="fa-solid fa-location-dot" style={{ color: `${pallete.primary}` }}></i><span>آدرس: </span><span>{data.address}</span></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}
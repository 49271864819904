import styled from "styled-components";
import { device, pallete } from "../../../theme";

export const Style = styled.div`
padding: 90px 0;
background: ${pallete.borderColor};
.items {
  width: 100%;
  .item {
    padding: 30px;
    min-height: 280px;
    width: 100%;
    background: ${pallete.whiteColor1};
    .box {
      width: 35%;
      .title {
        .title-t, .title-c {
          font-size: 1.8rem;
          color: ${pallete.primary};
          font-weight: 800;
        }
      }
      .number {
        .number-t, .number-c {
          font-size: 1.4rem;
          color: ${pallete.dark};
        }
        .number-h {
          font-size: 1.3rem;
          color: ${pallete.dark};
        }
      }
      .quantity {
        .quantity-t, .quantity-c, .quantity-u {
          font-size: 1.4rem;
          color: ${pallete.dark};
        }
      }
      .closing {
        .closing-t, .closing-c {
          font-size: 1.4rem;
          color: ${pallete.dark};
        }
      }
      .delivery {
        .delivery-t, .delivery-c {
          font-size: 1.4rem;
          color: ${pallete.dark};
        }
      }
    }
    .desc {
      width: 50%;
      .desc-c {
        font-size: 1.4rem;
        color: ${pallete.dark};
        line-height: 2;
      }
    }
  }
}
@media only screen and ${device.lg} {
  padding-top: 150px;
  .items {
    .item {
      .box {
        .title {
          .title-t, .title-c {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
@media only screen and ${device.md} {
  padding-top: 90px;
  .items {
    .item {
      .box {
        .title {
          .title-t, .title-c {
            font-size: 1.4rem;
          }
        }
        .number {
          .number-t, .number-c {
            font-size: 1.2rem;
          }
          .number-h {
            font-size: 1.2rem;
          }
        }
        .quantity {
          .quantity-t, .quantity-c, .quantity-u {
            font-size: 1.2rem;
          }
        }
        .closing {
          .closing-t, .closing-c {
            font-size: 1.2rem;
          }
        }
        .delivery {
          .delivery-t, .delivery-c {
            font-size: 1.2rem;
          }
        }
      }
      .desc {
        .desc-c {
          font-size: 1.2rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .items {
    .item {
      .top {
        flex-direction: column;
        align-items: center;
        .box, .desc {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and ${device.xs} {
  .items {
    .item {
      .box {
        .title {
          .title-t, .title-c {
            font-size: 1.4rem;
          }
        }
        .number {
          .number-t, .number-c {
            font-size: 1.1rem;
          }
          .number-h {
            font-size: 1.1rem;
          }
        }
        .quantity {
          .quantity-t, .quantity-c, .quantity-u {
            font-size: 1.1rem;
          }
        }
        .closing {
          .closing-t, .closing-c {
            font-size: 1.1rem;
          }
        }
        .delivery {
          .delivery-t, .delivery-c {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
`;
import { Button, Result } from 'antd';
import { Style } from './style';


export default function ServerError() {
    return (
        <Style>
            <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
                extra={<Button type="primary">Back Home</Button>}
            />
        </Style>
    );
}
import { Form, Input } from "antd";
import { Style } from "./style";

export default function InputElement(props) {
  return (
    <Style>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={props.rules}>
        <Input prefix={props.prefix} value={props.vale} onChange={props.onChange} placeholder={props.placeholder} disabled={props.disabled} showCount={props.showCount} maxLength={props.maxLength} />
      </Form.Item>
    </Style>
  );
}
import { Link } from "react-router-dom";
import FloatBtn from "../../../Components/uiElements/Antd/FloatBtn";
import { CommentOutlined, FormOutlined, UserOutlined, CustomerServiceOutlined } from '@ant-design/icons';

export default function ActionButton() {
  const id = localStorage.getItem('id')
  const items = [
    {
      icon: <Link to={`/submit/${id}`}><UserOutlined /></Link>,
      tooltip: 'ویرایش پروفایل',
    },
    {
      icon: <Link to={`/submit/${id}`}><CommentOutlined /></Link>,
      tooltip: 'ثبت استعلام',
    },
    {
      icon: <Link to={`/submit/${id}`}><FormOutlined /></Link>,
      tooltip: 'ثبت محصول',
      // children: [],
      // badge: {
      //   count: '',
      //   color: '',
      // }, 
    },
  ];
  return (
    <FloatBtn type="primary" trigger='click' left={14} bottom={15} icon={<CustomerServiceOutlined />} items={items} shape='circle'/>
  );
}
import { Style } from "./style";
import HelmetFunction from '../../../Components/Helmet';
import PrimaryLayout from '../../../Components/Layout/PrimaryLayout';
import { useEffect, useState, Fragment } from "react";
import { api } from "../../../Utils/api";
import Button from "../../../Components/uiElements/Button";
import { useParams } from "react-router-dom";
import { baseDirection } from "../../../Utils/FileHandling";
import { HandleUnit } from "../../../Components/Categories/Units";
import Loading from '../../../Components/Loading';
import { pallete } from "../../../theme";
import SubmitInquiry from "../../Inquiry/SubmitInquiry";


export default function SingleRfq() {
  const moment = require('moment-jalaali');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [data, setData] = useState({
    id: '',
    custom_id: '',
    title: '',
    description: '',
    document_path: baseDirection.files,
    quantity: '',
    unit: '',
    submit_date: '',
    closing_date: '',
    currency: '',
    budget: '',
    payment_term: '',
    delivery_term: '',
    delivery_location: '',
    delivery_time: '',
    certificate: '',
    other: '',
    user_id: '',
  });
  useEffect(() => {
    setLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    getApi();
  }, []);
  function getApi() {
    api
      .get(`rfq/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.rfq);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error.response.data.message);
      });
  }
  function handleTime() {
    const today = moment(Date()).format('jYYYY/jMM/jDD');
    const close = moment(data.closing_date).format('jYYYY/jMM/jDD');
    const daysDiff = moment(close).diff(moment(today), 'days');
    if (daysDiff > 0) {
      return (
        <Fragment>
          <img className="img" src="/asset/image/open.png" />
          <span className="text">
            {`مهلت ارسال پیشنهاد فنی: ${daysDiff} روز`}
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <img className="img" src="/asset/image/closed.png" />
          <span className="text">
            {'مهلت ارسال پیشنهاد فنی به اتمام رسیده است.'}
          </span>
        </Fragment>
      );
    }
  }

  function renderFarm() {
    return (
      <Fragment>
        <div className='flex flex-column justify-center gap-20'>
          <div className="content">
            <div className="content-inner flex flex-column gap-20">
              <div className="top flex justify-between">
                <div className="flex flex-column gap-10">
                  <div className="title flex align-center gap-5">
                    <h1 className="title-c">{data.title}</h1>
                  </div>
                  <div className="id flex align-center gap-5">
                    <p className="id-t">شماره استعلام: </p>
                    <p className="id-c">{data.custom_id}</p>
                    <p className="id-prefix"> - TDX</p>
                  </div>
                  <div className="quantity flex align-center gap-5">
                    <i className="fa-brands fa-stack-overflow"></i>
                    <p className="quantity-t">تعداد / مقدار: </p>
                    <p className="quantity-c">{data.quantity}</p>
                    <p className="quantity-u">{HandleUnit(data.unit)}</p>
                  </div>
                  <div className="closing flex align-center gap-5">
                    <i className="fa-brands fa-expeditedssl"></i>
                    <p className="closing-t">مهلت ارسال پیشنهاد: </p>
                    <p className="closing-c">{moment(data.closing_date).format('jYYYY/jMM/jDD')}</p>
                  </div>
                  <div className="delivery flex align-center gap-5">
                    <i className="fa-solid fa-calendar-check"></i>
                    <p className="delivery-t">موعد تحویل: </p>
                    <p className="delivery-c">{moment(data.delivery_time).format('jYYYY/jMM/jDD')}</p>
                  </div>
                  <div className="delivery-term flex align-center gap-5">
                    <i className="fa-solid fa-truck-ramp-box"></i>
                    <p className="delivery-term-t">شرایط تحویل: </p>
                    <p className="delivery-term-c">{data.delivery_term}</p>
                  </div>
                  <div className="payment flex align-center gap-5">
                    <i className="fa-solid fa-money-bill-1-wave"></i>
                    <p className="payment-t">شرایط پرداخت: </p>
                    <p className="payment-c">{data.payment_term}</p>
                  </div>
                  <div className="currency flex align-center gap-5">
                    <i className="fa-solid fa-coins"></i>
                    <p className="currency-t">واحد پولی: </p>
                    <p className="currency-c">{data.currency}</p>
                  </div>
                  <div className="certificate flex align-center gap-5">
                    <i className="fa-solid fa-certificate"></i>              <p className="certificate-t">نیاز به ارائه گواهی: </p>
                    <p className="certificate-c">{data.certificate === 'yes' ? 'بله' : 'خیر'}</p>
                  </div>
                </div>
                <div className="flex align-start justify-center">
                  <p className="condition flex flex-column gap-5 align-center">{handleTime()}</p>
                </div>
              </div>
              <div className="desc flex gap-5">
                <p className="desc-c">{data.description}</p>
              </div>
              {data.other && <div className="other flex gap-5">
                <p className="other-t">سایر: </p>
                <p className="other-c">{data.description}</p>
              </div>}
              <div className="location flex gap-5">
                <i className="fa-solid fa-location-dot"></i>
                <p className="location-t">آدرس: </p>
                <p className="location-c">{data.delivery_location}</p>
              </div>
              <div className="btn flex justify-center align-center gap-5">
                <Button target='_blanck' href={data.document_path && baseDirection.files.concat(data.document_path)} type="dark" size="large">دریافت مستندات</Button>
              </div>
            </div>
          </div>
          <SubmitInquiry rfq_id={data.custom_id} currency={data.currency} closing_date={data.closing_date} />
        </div>
      </Fragment >
    );
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='صفحه استعلام' />
      <Style>
        <div className="container-l">
          {loading ? <Loading /> : renderFarm()}
        </div>
      </Style>
    </PrimaryLayout>
  );
}
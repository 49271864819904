import { Style } from "./style";
import { Link } from "react-router-dom";
import Button from "../uiElements/Button";
import { ProductCategories } from '../Categories/ProductCategories';
import Menu from "../uiElements/Menu";
import { CompanyCategories } from "../Categories/companyCategories";
import { useState, useEffect, Fragment } from "react";
import { api } from '../../Utils/api';
import ResponsiveHeader from "./Responsive";


export default function Header(props) {
  const { hbgcolor } = props;
  const access_token = localStorage.getItem('access_token');
  const id = localStorage.getItem('id');
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [token, setToken] = useState(Boolean(access_token));
  const [top, setTop] = useState(false);
  const [scroll, setScroll] = useState(window.scrollY);

  useEffect(() => {
    if (token) {
      setUsername(localStorage.getItem('username'));
    }
  }, [token]);
  function handleLogout() {
    const access = localStorage.getItem('access_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api
      .post('/logout')
      .then((response) => {
        setToken(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    handleTop();
  }, [scroll]);
  function handleTop() {
    if (window.scrollY - 10 > scroll) {
      setScroll(window.scrollY);
      setTop(true);
    } else if (window.scrollY < scroll) {
      setScroll(window.scrollY);
      setTop(false);
    }
  }
  window.addEventListener('scroll', handleTop);

  return (
    <Fragment>
      <ResponsiveHeader hbgcolor={hbgcolor} className={top ? 'responsive header-hide' : 'responsive'} />
      <Style hbgcolor={hbgcolor}
        className={top ? 'header header-hide' : 'header'}>
        <div className="container">
          <div className="content flex align-center justify-between nowrap">
            <div className="menu flex align-center gap-20">
              <div className="logo">
                <Link className="image" to={'/'}>
                  <img className="img" src="/asset/image/logo.svg" />
                </Link>
              </div>
              <div>
                <Button type='default' icon={"fa-solid fa-house"} href='/'>
                  صفحه نخست
                </Button>
              </div>
              <div className="category flex align-center">
                <Button href={'/products'} type='default' icon={"fa-solid fa-layer-group"}>
                  <span>دسته بندی ها</span>
                </Button>
                <div className='category-items'>
                  <Menu dir='rtl' menuData={ProductCategories} />
                </div>
              </div>
              <div className="company flex align-center">
                <Button href={'/companies'} type='default' icon={"fa-solid fa-industry"}>شرکت ها</Button>
                <div className="company-items">
                  <Menu dir='rtl' menuData={CompanyCategories} />
                </div>
              </div>
              {/* <div className="rfq flex align-center">
                <Button href='/rfqs' type='default' icon={"fa-solid fa-ellipsis-vertical"}>لیست استعلام قیمت ها</Button>
              </div> */}
            </div>
            <div className="action flex align-center gap-20">
              {/* <div className="sell">
                <Button href={'/'} type='dark'>فروش در تدارکس</Button>
              </div> */}
              <div className="rfq flex align-center">
                <Button href='/rfqs' type='dark' icon={"fa-solid fa-ellipsis-vertical"}>لیست استعلام قیمت ها</Button>
              </div>
              <div className="search flex aling-center gap-5">
                <Button type='primary' icon={'fa-solid fa-search'} href='/search'>جستجو</Button>
              </div>
              <div className="login">
                {token
                  ?
                  <div>
                    <Button type='default' >
                      {username} | پروفایل
                    </Button>
                    <div className="login-items flex align-center justify-center gap-10">
                      <Link className="btn flex align-center gap-5" to={`/profile/${id}`}><i className="fa-regular fa-user"></i><span>پروفایل</span></Link>
                      <a className="btn flex align-center gap-5" href="/" onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket"></i><span>خروج</span></a>
                    </div>
                  </div>
                  :
                  <Button href={'/auth'} type='default'>
                    ورود | ثبت نام
                  </Button>}
              </div>
            </div>
          </div>
        </div>
      </Style>
    </Fragment>

  );
}
import PrimaryLayout from "../../../Components/Layout/PrimaryLayout";
import Loading from "../../../Components/Loading";
import HelmetFunction from "../../../Components/Helmet";
import { pallete } from "../../../theme";
import { Style } from "./style";
import { useEffect, useState } from "react";
import { api } from "../../../Utils/api";
import { useSearchParams, createSearchParams } from "react-router-dom";
import Button from "../../../Components/uiElements/Button";
import { Pagination } from "antd";
import AvatarElement from "../../../Components/uiElements/Antd/Avatar";


export default function Companies() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [btnSize, setBtnSize] = useState('large');
  const [data, setData] = useState({
    users: [],
    metadata: {
      total: 1,
      per_page: 10,
      current_page: 1,
    },
  });
  useEffect(() => {
    setLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    getApi(searchParams.get('page'));
  }, [searchParams]);
  function getApi(page = 1, per_page = data.metadata.per_page) {
    api
      .get('ordered_users', { params: { page: page, per_page: per_page } })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error);
        console.log(error);
      });
  }
  useEffect(() => {
    handleBtnSize();
  }, []);
  function handleBtnSize() {
    const width = window.innerWidth;
    if (width > 600) {
      setBtnSize('large');
    } else {
      setBtnSize('medium');
    }
  }
  function handleChange(page) {
    setSearchParams(createSearchParams({ page: page, per_page: data.metadata.per_page }));
  }
  function renderFarm() {
    return (
      data.users.map((item, index) => {
        return (
          <li className="item" key={index}>
            <div className="flex flex-column gap-10 align-center">
              <div className="inner-content">
                <div className="top flex align-center justify-between">
                  <h1 className="title">{item.username}</h1>
                  <AvatarElement className="avatar" username={item.username} image={item.avatar} />
                </div>
                <div className="desc flex align-center justify-center">
                  <p className="about">{item.about && <>{item.about.substring(0, 350)}<>... </></>}</p>
                </div>
                <div className="exp-box flex flex-column">
                  <div>
                    <h3 className="exp-t">تخصص ها</h3>
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_1 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i><span>{item.expertise_1}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_2 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{item.expertise_2}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_3 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{item.expertise_3}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_4 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{item.expertise_4}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_5 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{item.expertise_5}</span></>}
                  </div>
                  <div className="exp-item flex align-center gap-5">
                    {item.expertise_6 && <><i className="fa-solid fa-circle-check" style={{ color: `${pallete.primary}` }}></i>
                      <span>{item.expertise_6}</span></>}
                  </div>
                </div>
                <div className=" need-box flex flex-column">
                  <div>
                    <h3 className="need-t">نیازهای کلیدی</h3>
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {item.need_1 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_1}</span></>}
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {item.need_2 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_2}</span></>}
                  </div>
                  <div className="need-item flex align-center gap-5">
                    {item.need_3 && <><i className="fa-solid fa-circle-exclamation" style={{ color: `${pallete.primary}` }}></i><span>{item.need_3}</span></>}
                  </div>
                </div>
                <div className=" contact-box flex flex-column">
                  <div>
                    <h3 className="contact-t">اطلاعات تماس</h3>
                  </div>
                  <div className="tel flex align-center gap-5">
                    {item.tel && <><i className="fa-solid fa-phone" style={{ color: `${pallete.primary}` }}></i><span>شماره تماس: </span><span>{item.tel}</span></>}
                  </div>
                  <div className="fax flex align-center gap-5">
                    {item.fax && <><i className="fa-solid fa-fax" style={{ color: `${pallete.primary}` }}></i><span>شماره فکس: </span><span>{item.fax}</span></>}
                  </div>
                  <div className="address flex align-center gap-5">
                    {item.address && <><i className="fa-solid fa-location-dot" style={{ color: `${pallete.primary}` }}></i><span>آدرس: </span><span>{item.address.substring(0, 80)}</span>...</>}
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button href={`/companies/company/${item.id}`} size={btnSize} type="dark">مشاهده پروفایل</Button>
                </div>
              </div>
            </div>
          </li>
        );
      })
    );
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='شرکت های فعال در تدارکس' />
      <Style>
        <div className="container-l">
          <div className="content flex flex-column align-center gap-50">
            <ul className="items flex flex-column align-center gap-20">
              {loading ? <Loading /> : renderFarm()}
            </ul>
            <Pagination showSizeChanger={false} style={{ direction: "ltr" }} pageSize={data.metadata.per_page} current={data.metadata.current_page} total={data.metadata.total} onChange={handleChange} />
          </div>
        </div>
      </Style>
    </PrimaryLayout>
  );
}
import styled from "styled-components";
import { device, pallete } from "../../../../theme";

export const Style = styled.div`
padding: 100px 0;
background: ${pallete.borderColor};
.content {
    .desc {
        background: ${pallete.whiteColor1};
        padding: 20px;
        .desc-t {
            font-size: 1.8rem;
            color: ${pallete.blue};
            padding: 5px 0;
            border-bottom: 2px solid ${pallete.primary};
        }
        .desc-b {
            font-size: 1.4rem;
            color: ${pallete.dark};
            line-height: 2;
        }
        .desc-img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            padding: 5px;
            border: 2px dotted ${pallete.primary};
            border-radius: ${pallete.defaultRadius};
        }
    }
    .items {
        width: 100%;
        .item {
            min-height: 300px;
            width: 100%;
            background: ${pallete.whiteColor1};
            padding: 30px;
            .item-title {
                font-size: 1.8rem;
                color: ${pallete.primary};
                padding: 5px 0;
                font-weight: 800;
            }
            .item-model {
                .item-model-t, .item-model-c {
                    font-size: 1.2rem;
                    color: ${pallete.dark};
                    font-weight: 600;
                }
            }
            .item-category  {
                .item-category-t, .item-category-c {
                    font-size: 1.2rem;
                    color: ${pallete.darkButtonhover};
                    font-weight: 600;
                }
            }
            .item-counterpart {
                .item-counterpart-t, .item-counterpart-c {
                    font-size: 1.2rem;
                    color: ${pallete.darkButtonhover};
                    font-weight: 600;
                }
            }
            .item-producer  {
                .item-producer-t, .item-producer-c {
                    font-size: 1.2rem;
                    color: ${pallete.darkButtonhover};
                    font-weight: 600;
                }
            }
            .item-image {
                width: 150px;
                height: 150px;
                object-fit: cover;
                box-shadow: 0 0 4px ${pallete.defaultBtnColor};
                // transition: all 1s ease;
                // border-radius: 50%;
                &:hover {
                    // transform: rotate(360deg) scale(1.2);
                    box-shadow: 0 0 4px ${pallete.primary};
                }
            }
            .item-desc {
                .item-desc-t, .item-desc-c {
                    font-size: 1.3rem;
                    color: ${pallete.borderColor};
                    font-weight: 600;
                }
            }
            .item-detail {
                margin: 10px 0 0 5px;
            }
            .detail {
                padding: 5px 0;
            }
        }
    }
}
@media only screen and ${device.lg} {
    padding: 150px 0;
}
@media only screen and ${device.md} {
    padding: 80px 0;
}
@media only screen and ${device.sm} {
    .content {
        .items {
            .item {
                .box {
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    .item-title {
                        font-size: 1.6rem;
                        font-weight: 600;
                    }
                }
                .item-desc {
                    .item-desc-t, .item-desc-c {
                        font-weight: 500;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}
@media only screen and ${device.xs} {
    .content {
        .items {
            .item {
                .box {
                    .item-title {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}
`;
import PrimaryLayout from '../../../Components/Layout/PrimaryLayout';
import HelmetFunction from '../../../Components/Helmet';
import { Style } from './style';
import { api } from '../../../Utils/api';
import { useEffect, useState } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import Loading from '../../../Components/Loading';
import Button from '../../../Components/uiElements/Button';
import { Pagination } from 'antd';
import { HandleUnit } from '../../../Components/Categories/Units';
import moment from "moment";
import { pallete } from '../../../theme';



export default function Rfqs() {
  const moment = require('moment-jalaali');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [data, setData] = useState({
    result: [],
    metadata: {
      total: 1,
      current_page: 1,
      per_page: 10,
    }
  });
  useEffect(() => {
    setLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    getApi(searchParams.get('page'));
  }, [searchParams]);
  function getApi(page = 1, per_page = data.metadata.per_page) {
    api
      .get('rfq_list', { params: { page: page, per_page: per_page } })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error);
      });
  }
  function handleChange(page) {
    setSearchParams(createSearchParams({ page: page, per_page: data.metadata.per_page }));
  }
  function renderFarm() {
    return (
      data.result.map((item, index) => {
        return (
          <li key={index} className="item">
            <div className=' top flex justify-between'>
              <div className='box flex flex-column gap-10'>
                <div className="title">
                  <span className="title-c">{item.title}</span>
                </div>
                <div className="number flex align-center gap-5">
                  <span className="number-t">شماره: </span>
                  <span className="number-c">{item.custom_id}</span>
                  <span className="number-h"> - TDX</span>
                </div>
                <div className="quantity flex align-center gap-5">
                  <span className="quantity-t">تعداد / مقدار: </span>
                  <span className="quantity-c"> {item.quantity} </span>
                  <span className="quantity-u">{HandleUnit(item.unit)}</span>
                </div>
                <div className="closing flex align-center gap-5">
                  <span className="closing-t">مهلت ارسال پیشنهاد: </span>
                  <span className="closing-c">{moment(item.closing_date).format('jYYYY/jMM/jDD')}</span>
                </div>
                <div className="delivery flex align-center gap-5">
                  <span className="delivery-t">تاریخ تحویل: </span>
                  <span className="delivery-c">{moment(item.delivery_time).format('jYYYY/jMM/jDD')}</span>
                </div>
              </div>
              <div className="desc flex gap-5">
                <span className="desc-c">{item.description.length > 350 ? <>
                  <>{item.description.substring(0, 350)}</><>...</></> : item.description}</span>
              </div>
            </div>
            <div className="detail flex justify-center mtb-10">
              <Button type="dark" href={`rfq/${item.custom_id}`}>مشاهده جزئیات</Button>
            </div>
          </li>
        );
      })
    );
  }
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='لیست استعلام های ثبت شده در تدارکس' />
      <Style>
        <div className="container-l">
          <div className='flex flex-column align-center gap-20'>
            <ul className="items flex flex-column align-center gap-25">
              {loading ? <Loading /> : renderFarm()}
            </ul>
            <Pagination showSizeChanger={false} style={{ direction: "ltr" }} pageSize={data.metadata.per_page} current={data.metadata.current_page} total={data.metadata.total} onChange={handleChange} />
          </div>
        </div>
      </Style>
    </PrimaryLayout>
  );
}
export const Units = [
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'gr',
    value: 'gr',
  },
  {
    label: 'kg',
    value: 'kg',
  },
  {
    label: 'ton',
    value: 'ton',
  },
  {
    label: 'm',
    value: 'm',
  },
  {
    label: 'm2',
    value: 'm2',
  },
  {
    label: 'm3',
    value: 'm3',
  },
  {
    label: 'Lit',
    value: 'Lit',
  },
  {
    label: 'mLit',
    value: 'mLit',
  },
  {
    label: 'set',
    value: 'set',
  },
  {
    label: 'gal',
    value: 'gal',
  },
  {
    label: 'lb',
    value: 'lb',
  },
];

export const HandleUnit = (unit) => {
  if (unit === 'No') {
    return "عدد";
  } else if (unit === 'gr') {
    return "گرم";
  } else if (unit === 'kg') {
    return 'کیلوگرم';
  } else if (unit === 'ton') {
    return 'تن';
  } else if (unit === 'm') {
    return 'متر';
  } else if (unit === 'm2') {
    return 'متر مربع';
  } else if (unit === 'm3') {
    return 'متر مکعب';
  } else if (unit === 'Lit') {
    return 'لیتر';
  } else if (unit === 'set') {
    return 'ست';
  } else if (unit === 'lb') {
    return 'پوند';
  } else if (unit === 'mLit') {
    return 'میلی لیتر';
  } else if (unit === 'gal') {
    return 'گالن';
  }
};
import { Style } from "./style";
import RecentProducts from "../../../Components/Slider/MultiItems/Products";
import ProductCarousel from "../../../Components/uiElements/Antd/Carousel/Products";


export default function ProductSlider() {
  return (
    <Style>
      <div className="img"></div>
      <RecentProducts page={1} per_page={20} title={'محصولات ثبت شده در تدارکس'} />
      {/* <ProductCarousel page={1} per_page={45} title={'محصولات ثبت شده در تدارکس'}/> */}
    </Style>
  );
}
import { Style } from "./style";
import { Link } from "react-router-dom";

export default function Social() {
  return (
    <Style className="flex align-center justify-center gap-15">
      <div className="item">
        <Link to={'/'}>
          <i className="fa-brands fa-instagram"></i>
        </Link>
      </div>
      <div className="item">
        <Link to={'/'}>
          <i className="fa-brands fa-linkedin"></i>
        </Link>
      </div>
      <div className="item">
        <Link to={'/'}>
          <i className="fa-brands fa-telegram"></i>
        </Link>
      </div>
      <div className="item">
        <Link to={'/'}>
          <i className="fa-brands fa-whatsapp"></i>
        </Link>
      </div>
    </Style>
  );
}
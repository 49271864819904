import { Style } from "./style";
import PrimaryLayout from "../../Components/Layout/PrimaryLayout";
import HelmetFunction from "../../Components/Helmet";
import { pallete } from "../../theme";

export default function AboutUs() {
  return (
    <PrimaryLayout hbgcolor={pallete.dark}>
      <HelmetFunction title='درباره ما' />
      <Style>
        <div className="container-l">
          <div className="about-top flex flex-column gap-10 mtb-10">
            <div className="flex align-center gap-5">
              <img className="about-top-icon" src="/asset/image/info.png" />
              <h1 className="about-top-h">درباره ما</h1>
            </div>
            <p className="about-top-b">پلتفرم  تدارکس یک بازار B2B آنلاین است که به شرکت‌های بالادستی در صنایع نفت، گاز و پتروشیمی، فولاد، مواد معدنی، سیمان و سایر صنایع مرتبط، امکان ارتباط و همکاری با تامین کنندگان داخلی جهت رفع نیاز کالاها و خدمات درخواستی را می‌دهد. این پلتفرم با ارائه خدمات متنوعی مانند جستجوی محصولات و خدمات، امکان جستجوی کالای وارداتی و مشاهده تولیدکنندگان داخلی، مقایسه قیمت‌ها، مدیریت سفارشات و پرداخت‌ها، امکان داخلی سازی محصول مورد نیاز و .... به شرکت‌ها کمک می‌کند تا فرآیند خرید خود را بهینه‌سازی کرده و هزینه‌های خود را کاهش دهند.</p>
          </div>
          <div className="about-service">
            <h3 className="about-service-t">خدمات تدارکس</h3>
            <p className="about-service-b">پلتفرم تدارکس خدمات متنوعی را به شرکت‌های بالادستی ارائه می‌دهد که از جمله آنها می‌توان به موارد زیر اشاره کرد:</p>
            <ul className="about-service-items flex flex-column gap-10">
              <li className="item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="item-icon fa-solid fa-plus"></i>
                  <span className="item-t">جستجوی محصولات و خدمات: </span>
                </div>
                <span className="item-b">شرکت‌ها می‌توانند با استفاده از ابزار جستجوی پیشرفته پلتفرم، محصولات و خدمات مورد نیاز خود را از بین هزاران تامین‌کننده موجود در پلتفرم پیدا کنند. لازم به ذکر است نقطه قوت تدارکس امکان جستجوی کالای وارداتی و مشاهده تولیدکنندگان داخلی برای کالای مذکور است.</span>
              </li>
              <li className="item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="item-icon fa-solid fa-plus"></i>
                  <span className="item-t">مقایسه قیمت ها: </span>
                </div>
                <span className="item-b">شرکت‌ها می‌توانند قیمت محصولات و خدمات مختلف را از تامین‌کنندگان مختلف مقایسه کنند و بهترین گزینه را برای خود انتخاب کنند. همچنین با توجه به محدودیت¬های آیین نامه ای شرکتها می¬تواند تقاضای خود را برای دریافت سه یا تعداد بیشتری پیشنهاد از شرکتهای مختلف در پاکت¬های در بسته ثبت نمایند.</span>
              </li>
              <li className="item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="item-icon fa-solid fa-plus"></i>
                  <span className="item-t">مدیریت سفارشات: </span>
                </div>
                <span className="item-b">شرکت‌ها می‌توانند سفارشات خود را به صورت آنلاین ثبت کنند و روند پیشرفت سفارش خود را پیگیری کنند.</span>
              </li>
              <li className="item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="item-icon fa-solid fa-plus"></i>
                  <span className="item-t">پرداخت ها: </span>
                </div>
                <span className="item-b">شرکت‌ها می‌توانند به صورت آنلاین و با استفاده از روش‌های مختلف پرداخت، هزینه سفارشات خود را پرداخت کنند  و یا مطابق با قوانین داخلی خود هزینه سفارش را پس از دریافت کالا پرداخت نمایند.</span>
              </li>
            </ul>
          </div>
          <div className="plus">
            <h3 className="plus-t">مزایای استفاده از پلتفرم</h3>
            <p className="plus-b">استفاده از پلتفرم تدارکس مزایای متعددی برای شرکت‌های بالادستی دارد که از جمله آنها می‌توان به موارد زیر اشاره کرد:</p>
            <ul className="plus-items flex flex-column gap-10">
              <li className="plus-item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="plus-item-icon fa-solid fa-plus"></i>
                  <span className="plus-item-t">کاهش هزینه ها: </span>
                </div>
                <span className="plus-item-b">پلتفرم تدارکس به شرکت‌ها کمک می‌کند تا با امکان یافتن مشابه داخلی، هزینه‌های خرید خود را کاهش دهند.</span>
              </li>
              <li className="plus-item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="plus-item-icon fa-solid fa-plus"></i>
                  <span className="plus-item-t">افزایش بهره وری: </span>
                </div>
                <span className="plus-item-b">پلتفرم تدارکس فرآیند خرید و فروش را برای شرکت‌ها ساده‌تر و کارآمدتر می‌کند.</span>
              </li>
              <li className="plus-item flex flex-column gap-10">
                <div className="flex align-center gap-5">
                  <i className="plus-item-icon fa-solid fa-plus"></i>
                  <span className="plus-item-t">دسترسی به بازارهای جدید: </span>
                </div>
                <span className="plus-item-b">پلتفرم تدارکس به شرکت‌ها امکان می‌دهد تا با تامین‌کنندگان و مشتریان جدید در سراسر کشور و حتی جهان ارتباط برقرار کنند.</span>
              </li>
            </ul>
          </div>
          <div className="perspective flex flex-column gap-10">
            <h3 className="perspective-t">چشم انداز</h3>
            <p className="perspective-b">پلتفرم تدارکس در نظر دارد با ارائه خدمات جدید و نوآورانه، به پلتفرم مرجع برای شرکت‌های بالادستی در ایران تبدیل شود. این پلتفرم با همکاری با شرکت‌های پیشرو در صنایع مختلف، به دنبال ایجاد بستری برای توسعه و رشد این صنایع در ایران است.</p>
          </div>
          <div className="conclusion flex flex-column gap-10">
            <h3 className="conclusion-t">سخن پایانی</h3>
            <p className="conclusion-b">پلتفرم تدارکس یک پلتفرم B2B قدرتمند و کارآمد است که می‌تواند به شرکت‌های بالادستی در بهبود فرآیند خرید و به تولید کنندگان در فرآیند فروش خود کمک کند. این پلتفرم با ارائه خدمات متنوع و نوآورانه، به دنبال تبدیل شدن به پلتفرم مرجع برای این شرکت‌ها در ایران است.</p>
          </div>
        </div>
      </Style>
    </PrimaryLayout>
  );
}
import { Style } from "./style";
import { useEffect, useState } from "react";
import { api } from "../../../../Utils/api";
import { message } from "antd";
import Loading from "../../../../Components/Loading";
import InputElement from "../../../../Components/uiElements/Antd/Input";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCreative } from 'swiper/modules';
import Button from "../../../../Components/uiElements/Button";
import { Link } from "react-router-dom";
import { baseDirection } from '../../../../Utils/FileHandling';

export default function MyInquiries() {
  const token = localStorage.getItem('access_token');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    const filtered = data.filter((item) => {
      const search = searchTerm.toLowerCase();
      return (
        item.custom_id.toLowerCase().includes(search) ||
        item.rfq_id.toLowerCase().includes(search)
      );
    });
    setFilteredData(filtered);
  }, [searchTerm, data]);
  useEffect(() => {
    setLoading(true);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api
      .get('my_inquiry_list')
      .then((response) => {
        setLoading(false);
        setData(response.data.result);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error.message);
      });
  }, []);
  useEffect(() => {
    if (msg) {
      message.info(msg);
      setMsg('');
    }
  }, [msg]);
  function renderFarm() {
    return (filteredData.map((item, id) => {
      return (
        <li key={id}>
          <div className="item flex align-center justify-center">
            <p className="item-number col-4 flex align-center justify-center">{id + 1}</p>
            <p className="item-id col-8 flex align-center justify-center">{item.custom_id}</p>
            <p className="item-rfq col-8 flex align-center justify-center">
              <Link target="_blank" to={`/rfqs/rfq/${item.custom_id}`}>{item.rfq_id}</Link>
            </p>
            <p className="item-detail col-8 flex align-center justify-center"><Button type='primary' target="_blank" href={baseDirection.files.concat(item.document_path)} >دانلود مستندات</Button></p>
          </div>
        </li>
      );
    }));
  }
  function renderFarmMobile() {
    return (filteredData.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className="item flex flex-column justify-center align-center gap-50">
            <div className="flex flex-column justify-center align-center gap-10">
              <div className="flex flex-column justify-center align-center gap-5">
                <div className="image">
                  <img className="img" src='/asset/image/business-proposal.png' />
                </div>
                <div className="item-id flex align-center gap-5">
                  <p>شماره پیشنهاد: </p>
                  <p>{item.custom_id}</p>
                </div>
                <div className="item-rfq">
                  <Link className="link flex align-center gap-5" to={`/rfqs/rfq/${item.custom_id}`} target="_blank">
                    <p>شماره استعلام: </p>
                    <p>{item.rfq_id}</p>
                  </Link>
                </div>
              </div>
              <div className="item-btn flex align-center gap-5">
                <Button type='dark' href={baseDirection.files.concat(item.document_path)}>دانلود مستندات</Button>
              </div>
            </div>
            <div className="item-no flex align-center gap-5">
              <span><>{index + 1}</><> از </><>{filteredData.length}</></span>
            </div>
          </div>
        </SwiperSlide>
      );
    }));
  }
  return (
    <Style>
      <InputElement prefix={<i className="fa-solid fa-search"></i>} type="text" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); }} placeholder="جستجو ..." />
      <div className="large-screen">
        <ul className="items">
          <li>
            <div className="header flex align-center justify-center">
              <p className="header-item col-4 flex align-center justify-center">ردیف</p>
              <p className="header-item col-8 flex align-center justify-center">شماره پیشنهاد</p>
              <p className="header-item col-8 flex align-center justify-center">شماره استعلام</p>
              <p className="header-item col-8 flex align-center justify-center">مشاهده</p>
            </div>
          </li>
          {loading ? <Loading /> : renderFarm()}
        </ul>
      </div>
      <div className="mobile">
        <Swiper
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['-100%', 0, 0],
            },
          }}
          modules={[EffectCreative]}
        >
          {loading ? <Loading /> : renderFarmMobile()}
        </Swiper>
      </div>
    </Style>
  );
}
import { Style } from "./style";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from "react-router-dom";
import Loading from "../../../Loading";
import { useEffect, useState } from "react";
import { api } from "../../../../Utils/api";
import moment from "moment";
import { HandleUnit } from "../../../Categories/Units";
import { Fragment } from "react";

export default function RecentRfqs({ page, per_page, title }) {
  const moment = require('moment-jalaali');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [data, setData] = useState({
    result: [],
    metadata: {
      total: 1,
      per_page: per_page,
      current_page: page,
    },
  });
  useEffect(() => {
    getApi(page, per_page);
  }, []);
  function getApi(page = 1, per_page = data.metadata.per_page) {
    setLoading(true);
    api
      .get('rfq_list', { params: { page: page, per_page: per_page } })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error);
        console.log(error);
      });
  }
  function handleTime(close) {
    const today = moment(Date()).format('jYYYY/jMM/jDD');
    const daysDiff = moment(close).diff(moment(today), 'days');
    if (daysDiff > 0) {
      return (
        <Fragment>
          <img className="img" src="/asset/image/open.png" />
          <span className="text">
            {`مهلت ارسال پیشنهاد: ${daysDiff} روز`}
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <img className="img" src="/asset/image/closed.png" />
          <span className="text">
            {'بسته شده است!'}
          </span>
        </Fragment>
      );
    }
  }
  function renderFarm() {
    return (
      data.result.map((item, index) => {
        return (
          <SwiperSlide key={index} className="item">
            <Link to={`/rfqs/rfq/${item.custom_id}`}>
              <div className='box flex flex-column gap-10'>
                <div className="flex flex-column align-center gap-10">
                  <div className="flex flex-column gap-10">
                    <p className="condition flex flex-column gap-5 align-center">{handleTime(moment(item.closing_date).format('jYYYY/jMM/jDD'))}</p>
                  </div>
                  <div className="title">
                    <span className="title-c">{item.title.length > 20 ? <>{item.title.substring(0, 20)} ...</> : item.title}</span>
                  </div>
                </div>
                <div className="number flex align-center justify-center gap-5">
                  <span className="number-t">شماره: </span>
                  <span className="number-c">{item.custom_id}</span>
                  <span className="number-h"> - TDX</span>
                </div>
                <div className="quantity flex align-center justify-center gap-5">
                  <span className="quantity-t">تعداد / مقدار: </span>
                  <span className="quantity-c"> {item.quantity} </span>
                  <span className="quantity-u">{HandleUnit(item.unit)}</span>
                </div>
                <div className="closing flex align-center justify-center gap-5">
                  <span className="closing-t">مهلت ارسال پیشنهاد: </span>
                  <span className="closing-c">{moment(item.closing_date).format('jYYYY/jMM/jDD')}</span>
                </div>
                <div className="delivery flex align-center justify-center gap-5">
                  <span className="delivery-t">تاریخ تحویل: </span>
                  <span className="delivery-c">{moment(item.delivery_time).format('jYYYY/jMM/jDD')}</span>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })
    );
  }
  return (
    <Style>
      <div className="flex align-center justify-between nowrap">
        <h3 className="title">{title}</h3>
        <Link to={'/rfqs'} className="see-all flex align-center gap-5">
          <span>مشاهده همه</span>
          <i className="fa-solid fa-angles-left"></i>
        </Link>
      </div>
      <Swiper
        navigation
        slidesPerView={1}
        spaceBetween={5}
        modules={[Navigation]}
        breakpointsBase="window"
        breakpoints={{
          250: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          380: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          550: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          750: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 5,
          },
          1260: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        className="mySwiper"
      >
        {loading ? <Loading /> : renderFarm()}
      </Swiper>
    </Style>
  );
}
import { Style } from './style';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../../Utils/api';
import { Menu } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';



function getItem(label, key, icon, children, type) {
  return (
    {
      key,
      icon,
      children,
      label,
      type,
    }
  );
}

export default function Sidebar({ open, onClickOutside }) {
  const ref = useRef(null);
  const id = localStorage.getItem('id');
  const access_token = localStorage.getItem('access_token');
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [token, setToken] = useState(Boolean(access_token));

  const items = [
    token && getItem(<Link to={`/profile/${id}`}>پروفایل</Link>, 'profile', <UserOutlined />),
    getItem(<Link to={'/products'}>محصولات</Link>, 'category', <i className="fa-solid fa-layer-group"></i>, [
      getItem(<Link to={'/products/categories/rotary_mechanic'}>مکانیک دوار</Link>, "rotary_mechanical",),
      getItem(<Link to={'/products/categories/fixed_mechanic'}>مکانیک ثابت</Link>, "fixed_mechanical"),
      getItem(<Link to={'/products/categories/instrument'}>ابزار دقیق</Link>, "instrument",),
      getItem(<Link to={'/products/categories/electrical_hvac'}>برق و تهویه</Link>, "electrical_hvac",),
      getItem(<Link to={'/products/categories/insulation'}>وسایل عایق و آب بندی</Link>, "insulation"),
      getItem(<Link to={'/products/categories/pipping'}>لوله و اتصالات</Link>, "pipping"),
      getItem(<Link to={'/products/categories/valve'}>شیرآلات</Link>, "valve"),
      getItem(<Link to={'/products/categories/control_valve'}>شیرآلات کنترلی</Link>, "control_valve"),
      getItem(<Link to={'/products/categories/tools'}>ابزار</Link>, "tools"),
      getItem(<Link to={'/products/categories/chemicals'}>مواد شیمیایی، کاتالیست و رنگ</Link>, "chemicals",),
      getItem(<Link to={'/products/categories/lubricants'}>روانکارها (روغن و گریس)</Link>, "lubricants"),
      getItem(<Link to={'/products/categories/general_steel'}>آهن آلات</Link>, "general_steel"),
      getItem(<Link to={'/products/categories/lab'}>مواد شیمیایی و تجهیزات آزمایشگاه</Link>, "lab"),
      getItem(<Link to={'/products/categories/service'}>خدمات فنی و مهندسی</Link>, "service")
    ],),
    getItem(<Link to={'/companies'}>شرکت ها</Link>, "company", <i className="fa-solid fa-industry"></i>, [
      getItem(<Link to={'/companies'}>شرکت های برتر</Link>, 'top_companies'),
      getItem(<Link to={'/companies'}>شرکت های تولید کننده</Link>, 'producers'),
      getItem(<Link to={'/companies'}>شرکت های مصرف کننده</Link>, 'consumers'),
    ]),
    getItem(<Link to={'/rfqs'}>لیست استعلام قیمت</Link>, "rfqs", <i className="fa-solid fa-folder-tree"></i>),
    getItem(<Link to={'/search'}>جستجو</Link>, 'search', <SearchOutlined />),
    getItem(token ? <a onClick={handleLogout} href='/'>{username} | خروج</a> : <div>
      <Link to='/auth'>ورود | ثبت نام</Link>
    </div>, 'profile_login', <UserOutlined />),
  ];
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside && onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside, { capture: true });
    };
  }, [onClickOutside]);
  useEffect(() => {
    if (token) {
      setUsername(localStorage.getItem('username'));
    }
  }, [token]);
  function handleLogout() {
    const access = localStorage.getItem('access_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    api
      .post('/logout')
      .then((response) => {
        console.log(response.data);
        setToken(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (!open) {
    return null;
  }
  return (
    <Style ref={ref} className="menu" >
      <Menu style={{ fontSize: '1.3rem' }} mode="inline" items={items} />
    </Style>
  );
}
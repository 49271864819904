import styled from "styled-components";
import { pallete } from '../../../theme';

export const Style = styled.div`
padding: 10px;
background: ${pallete.whiteColor1};
border-left: ${(props) => (props.dir === 'ltr' && `4px solid ${pallete.primary}`)};
border-right: ${(props) => (props.dir === 'rtl' && `4px solid ${pallete.primary}
`)};
border-radius: ${pallete.defaultRadius};
box-shadow: ${pallete.borderColor} 0 0 4px;
.first-level {
  width: 250px;
  overflow: auto;
  .first-level-item {
    font-size: 1.4rem;
    padding: 3px;
    transition: all 0.3s ease;
    &:hover {
      font-weight: 700;
      .icon {
        color: ${pallete.dark};
      }
    }
    .icon {
      color: ${pallete.primary};
      transition: all 0.3s ease;
    }
  }
}
.second-level {
  width: 350px;
  .second-level-item {
    font-size: 1.4rem;
    padding: 3px;
    transition: all 0.3s ease;
    &:hover {
      background: ${pallete.primary};
    }
  }
}
`;
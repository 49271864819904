import styled from "styled-components";
import { device, pallete } from "../../../../theme";

export const Style = styled.div`
background-color: transparent;
padding: 90px 0 25px 0;
.content {
  width: 100%;
  .inner-content {
    min-height: 450px;
    color: ${pallete.dark};
    background: ${pallete.whiteColor1};
    padding: 30px;
    .top {
      .title {
        font-size: 2.2rem;
        font-weight: 900;
        color: ${pallete.primary};
      }
      .ant-avatar {
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        border: 3px solid ${pallete.primary};
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .desc {
      padding: 10px 0;
      .about {
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
    .exp-box {
      padding: 5px 0;
      .exp-t {
        margin-bottom: 10px;
        padding: 5px 0;
        font-size: 1.6rem;
        font-weight: 700;
        border-bottom: 2px solid ${pallete.defaultBtnColor};
        color: ${pallete.darkButtonhover};
      }
      .exp-item {
        font-size: 1.4rem;
        line-height: 1.5;
        color: ${pallete.dark};
      }
    }
    .need-box {
      padding: 5px 0;
      .need-t {
        margin-bottom: 10px;
        padding: 5px 0;
        font-size: 1.6rem;
        font-weight: 700;
        border-bottom: 2px solid ${pallete.defaultBtnColor};
        color: ${pallete.darkButtonhover};
      }
      .need-item {
        font-size: 1.4rem;
        line-height: 1.5;
        color: ${pallete.dark};
      }
    }
    .contact-box {
      min-height: 100px;
      padding: 5px 0;
      margin: 5px 0;
      .contact-t {
        margin-bottom: 5px;
        padding: 5px 0;
        font-size: 1.6rem;
        font-weight: 700;
        border-bottom: 1px solid ${pallete.defaultBtnColor};
        color: ${pallete.darkButtonhover};
      }
      .tel, .fax, .address {
        font-size: 1.2rem;
        color: ${pallete.dark};
      }
    }
  }
}
@media only screen and ${device.lg} {
  padding-top: 150px;
}
@media only screen and ${device.md} {
  padding-top: 90px;
}
@media only screen and ${device.sm} {
  .content {
    .inner-content {
      .desc {
        .about {
          font-size: 1.3rem;
        }
      }
      .exp-box {
        .exp-t {
          font-size: 1.4rem;
        }
        .exp-item {
          font-size: 1.1rem;
          line-height: 2;
        }
      }
      .need-box {
        .need-t {
          font-size: 1.4rem;
        }
        .need-item {
          font-size: 1.1rem;
          line-height: 2;
        }
      }
      .contact-box {
        .contact-t {
          font-size: 1.4rem;
        }
        .tel, .fax, .address {
          font-size: 1rem;
          line-height: 2;
        }
        .address {
          align-items: start;
        }
      }
    }
  }
}
@media only screen and ${device.xs} {
  .content {
    .inner-content {
      .top {
        .title {
          font-size: 2rem;
        }
        .ant-avatar {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}

`;
import styled from "styled-components";
import { pallete } from "../../../../theme";

export const Style = styled.div`
.ant-pagination {
  color: ${pallete.dark};
  background: ${pallete.defaultBtnColor};
  padding: 5px 10px;
  border-radius: ${pallete.defaultRadius};
  border: 2px solid ${pallete.whiteColor1};
  .ant-pagination-disabled {
    color: ${pallete.primary};
  }
}
`;